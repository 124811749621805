.FlexContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.FirstText{
    direction: rtl;
    width: 700px;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;
}
.Title{
    font-size: 30px;
}