td {
    direction: ltr !important;
}

.FlexContainer {
    margin-bottom: 70px;
}

.remove {
    text-align: center;
    font-size: 15px;
    padding-left: 7px !important;
    padding-right: 7px !important;
    cursor: pointer;
}

.Delicon {
    position: relative;
    top: 4px;
    color: #101130;
    transition: .2s;
}

.Delicon:hover {
    transition: .2s;
    color: #C3922E;
}

.ConfirmDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: fixed;
    width: 450px;
    height: 250px;
    z-index: 1100;
    top: 300px;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.514) 0px 0px 30px;
}

.TitleDelete {
    direction: rtl;
    font-size: 22px;
}

.BtContainer {
    display: flex;
    flex-direction: row-reverse;
}

.BtDelete {
    margin-left: 5px;
    margin-right: 5px;
    background-color: rgb(153, 51, 65);
    padding: 8px 0px 10px 0px;
    width: 120px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtCancel {
    margin-left: 5px;
    margin-right: 5px;
    background-color: #C3922E;
    padding: 8px 40px 10px 40px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtCancel:hover {
    background-color: #a37a29;
}

.BtDelete:hover {
    background-color: rgb(122, 35, 46);
    transition: .2s;
}

.tableWithSort {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SortIcon {
    position: relative;
    top: 4px;
    margin-right: 10px;
    cursor: pointer;
}

.downloadAndSearch {
    display: flex;
}

@media (min-width:1400px) {
    .ThDel {
        min-width: 80px;
    }
    .ThSite {
        min-width: 200px;
    }
    .ThDate {
        min-width: 270px;
    }
    .ThCar {
        min-width: 300px;
    }
    .ThId {
        min-width: 170px;
    }
}

@media (min-width:1201px) {
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Title {
        font-size: 40px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .InputContainer {
        margin-top: 120px;
        display: flex;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .Table {
        direction: rtl;
    }
    .Table {
        /* background-color: red; */
    }
    .Thead {}
    .Tr {
        height: 50px;
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        height: 50px;
        background-color: #F0F5F8;
        color: #101130;
        font-size: 20px;
        text-align: right;
        transition: .2s;
    }
    .TrData td {
        padding-right: 20px;
        padding-left: 50px;
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}

@media (max-width:1200px) and (min-width:768px) {
    .InputContainer {
        margin-top: 120px;
        display: flex;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .TableContainer {
        width: 90%;
    }
    .Title {
        font-size: 40px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .Table {
        direction: rtl;
    }
    .Table {
        /* background-color: red; */
    }
    .Thead {}
    .Tr {
        height: 50px;
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        height: 50px;
        background-color: #F0F5F8;
        color: #101130;
        font-size: 20px;
        text-align: right;
        transition: .2s;
        line-height: 20px;
    }
    .TrData td {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}

@media (max-width:767px) {
    .SortIcon{
        display: none;
    }
    .tableWithSort {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        

    }
    .InputContainer {
        margin-top: 120px;
        display: flex;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .TableContainer {
        width: 90%;
        margin-bottom: 50px;
    }
    .Title {
        font-size: 24px;
        margin-top: 120px;
        margin-bottom: 10px;
    }
    .Table {
        direction: rtl;
    }
    .Tr {
        /* height: 50px; */
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        /* height: 50px; */
        background-color: #F0F5F8;
        color: #101130;
        font-size: 16px;
        text-align: right;
        transition: .2s;
        line-height: 20px;
        margin-bottom: 5px;
    }
    .TrData td {
        direction: rtl;
        text-align: right !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
      
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}