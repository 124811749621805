.CarsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    direction: rtl;
    height: 500px;
}

.SingleCarContainer {
    flex: 0 0 30%;
    /* don't grow, don't shrink, width */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #00000036;
    position: relative;
}

.NameAndDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Price {
    background-color: rgb(228, 228, 228);
    height: 30px;
    font-family: Heebo;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px !important;
    direction: rtl;
}

@media (min-width:1451px) {
    .SingleCarContainer {
        margin: 20px;
    }
    .SingleCarContainer img {
        width: 420px;
    }
    .PriceTitle {
        width: 350px;
        height: 15px;
        background-color: rgb(228, 228, 228);
    }
    .SmallDescription {
        background-color: rgb(228, 228, 228);
        height: 35px;
        margin-bottom: 15px;
        margin-top: 5px;
        width: 70%;
    }
    .MoreInfo {
        width: 60px !important;
        height: 30px !important;
        background-color: rgb(228, 228, 228) !important;
        margin-bottom: 10px;
    }
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .PriceTitle {
        margin-top: 5px;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .MoreInfo {
        font-size: 21px;
        font-weight: 600;
        line-height: 27px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 5px;
        padding-top: 3px;
        border-radius: 5px;
        transition: .2s;
    }
    .MobilePic {
        overflow: hidden;
    }
    .MobilePriceAndInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Category {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 5px 10px 5px 10px;
        font-weight: 400;
        color: white;
        font-size: 20px;
        border-radius: 0px 7px 7px 0px;
    }
    .CarsContainer {
        width: 1400px;
        /* gap: 40px; */
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 80%;
        height: 2px;
        background-color: rgb(228, 228, 228);
    }
    .ModelName {
        font-size: 30px;
        line-height: 30px;
        font-weight: 600;
        color: #C3922E;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .Price {
        background-color: rgb(228, 228, 228);
        height: 30px;
        font-family: Heebo;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 5px;
        direction: rtl;
    }
    .Price span {
        font-size: 24px;
    }
}

@media (max-width:1450px) and (min-width:1201px) {
    .SingleCarContainer img {
        width: 330px;
    }
    .PriceTitle {
        width: 250px;
        height: 15px;
        background-color: rgb(228, 228, 228);
    }
    .SmallDescription {
        background-color: rgb(228, 228, 228);
        height: 35px;
        margin-bottom: 15px;
        margin-top: 5px;
        width: 50%;
    }
    .MoreInfo {
        width: 60px !important;
        height: 30px !important;
        background-color: rgb(228, 228, 228) !important;
        margin-bottom: 10px;
    }
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .PriceTitle {
        margin-top: 5px;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .MoreInfo {
        font-size: 21px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        background-color: #101130;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 5px;
        padding-top: 3px;
        border-radius: 5px;
        transition: .2s;
    }
    .MobilePriceAndInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Price {
        color: #C3922E;
        font-family: Heebo;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 5px;
        direction: rtl;
    }
    .Price span {
        font-size: 24px;
    }
    .ModelName {
        font-size: 30px;
        line-height: 30px;
        font-weight: 600;
        color: #C3922E;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .Category {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 5px 10px 5px 10px;
        font-weight: 400;
        background-color: #101130;
        color: white;
    }
    .Category {
        font-size: 18px;
        border-radius: 0px 7px 7px 0px;
    }
    .CarsContainer {
        width: 1100px;
        /* gap: 30px */
    }
   
    .SingleCarContainer {
        margin: 15px;
        width: 280px;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 80%;
        height: 2px;
        background: #C3922E;
    }
}

@media (max-width:1200px) and (min-width:768px) {
    .SingleCarContainer img {
        width: 340px;
    }
    .PriceTitle {
        width: 250px;
        height: 15px;
        background-color: rgb(228, 228, 228);
    }
    .SmallDescription {
        background-color: rgb(228, 228, 228);
        height: 35px;
        margin-bottom: 15px;
        margin-top: 5px;
        width: 50%;
    }
    .MoreInfo {
        width: 60px !important;
        height: 30px !important;
        background-color: rgb(228, 228, 228) !important;
        margin-bottom: 10px;
    }
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .PriceTitle {
        margin-top: 5px;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .MoreInfo {
        font-size: 21px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        background-color: #101130;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 5px;
        padding-top: 3px;
        border-radius: 5px;
        transition: .2s;
    }
    .MobilePriceAndInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Price {
        color: #C3922E;
        font-family: Heebo;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 5px;
        direction: rtl;
    }
    .Price span {
        font-size: 24px;
    }
    .ModelName {
        font-size: 30px;
        line-height: 30px;
        font-weight: 600;
        color: #C3922E;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .Category {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 5px 10px 5px 10px;
        font-weight: 400;
        background-color: #101130;
        color: white;
    }
    .Category {
        font-size: 18px;
        border-radius: 0px 7px 7px 0px;
    }
    .CarsContainer {
        width: 750px;
        /* gap: 30px */
    }
    .SingleCarContainer {
        margin: 15px;
        flex: 0 0 45%;
        width: 180px;
    }
    .SmallDescription {
        height: 40px;
        direction: rtl;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 24px;
        margin-top: 5px;
        color: #101130;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 80%;
        height: 2px;
        background: #C3922E;
    }
}

@media (max-width:767px) and (min-width:591px) {
    .SingleCarContainer img {
        width: 240px;
    }
    .PriceTitle {
        width: 200px;
        height: 10px;
        background-color: rgb(228, 228, 228);
    }
    .SmallDescription {
        background-color: rgb(228, 228, 228);
        height: 35px;
        margin-bottom: 15px;
        margin-top: 5px;
        width: 50%;
    }
    .MoreInfo {
        width: 60px !important;
        height: 30px !important;
        background-color: rgb(228, 228, 228) !important;
        margin-bottom: 10px;
    }
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .PriceTitle {
        margin-top: 5px;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .MoreInfo {
        font-size: 21px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        background-color: #101130;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 5px;
        padding-top: 3px;
        border-radius: 5px;
        transition: .2s;
    }
    .MobilePriceAndInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Price {
        color: #C3922E;
        font-family: Heebo;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 5px;
        direction: rtl;
    }
    .Price span {
        font-size: 24px;
    }
    .ModelName {
        font-size: 30px;
        line-height: 30px;
        font-weight: 600;
        color: #C3922E;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .Category {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 5px 10px 5px 10px;
        font-weight: 400;
        background-color: #101130;
        color: white;
    }
    .Category {
        font-size: 14px;
        border-radius: 0px 7px 7px 0px;
    }
    .CarsContainer {
        width: 560px;
        /* gap: 30px; */
    }

   

    .SingleCarContainer {
        margin: 15px;
        flex: 0 0 43%;
        width: 140px;
    }
    .SmallDescription {
        height: 40px;
        padding-left: 5px;
        padding-right: 5px;
        direction: rtl;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 24px;
        margin-top: 5px;
        color: #101130;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 80%;
        height: 2px;
        background: #C3922E;
    }
    .ModelName {
        padding-right: 2px;
        padding-left: 2px;
        font-size: 26px !important;
        line-height: 30px !important;
        margin-bottom: 0px !important;
    }
}

@media (max-width:590px) {
    .MobilePriceAndInfo {
        display: flex;
        flex-direction: row-reverse;
        /* background-color: #C3922E; */
        background-color: rgb(228, 228, 228);
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .SingleCarContainer img {
        width: 100%;
    }
    .PriceTitle {
        width: 150px;
        height: 10px;
        background-color: rgb(228, 228, 228);
    }
    .SmallDescription {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #101130;
        text-align: right;
        background-color: rgb(228, 228, 228);
        width: 150px;
        height: 20px;
    }
    /* .SmallDescription {
       
        height: 35px;
        margin-bottom: 15px;
        margin-top: 5px;
        width: 50%;
    } */
    .MoreInfo {
        width: 60px !important;
        height: 30px !important;
        background-color: rgb(228, 228, 228) !important;
        margin-bottom: 10px;
    }
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .CarsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        direction: rtl;
        min-height: 500px;
        flex-direction: column;
        width: 95%;
        /* gap: 25px; */
    }
    .SingleCarContainer {
        /* flex: 0 0 44%; */
        margin-bottom: 25px;
        width: 100%;
    }
    .Category {
        position: absolute;
        right: 0;
        top: 10px;
        padding: 2px 7px 3px 7px;
        font-weight: 400;
        color: white;
        font-size: 14px;
        border-radius: 7px 0px 0px 7px;
        background-color: #101130;
    }
    /* שם רכב ותיאור קצר */
    .MobileContainer {
        display: flex;
        width: 100%;
    }
    .MobilePic {
        font-size: 0px;
        width: 45%;
    }
    .NameAndDescription {
        padding-right: 30px;
        padding-left: 10px;
        overflow: hidden;
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        direction: rtl;
        overflow: hidden;
    }
    .ModelName {
        font-size: 24px;
        text-align: right;
        line-height: 22px;
        font-weight: 600;
        color: #C3922E;
        /* margin-top: 10px; */
        margin-bottom: 0px;
        margin-bottom: 10px;
        background-color: rgb(228, 228, 228) !important;
        height: 30px;
        width: 90%;
    }
    /* שם רכב ותיאור קצר */
    .PriceContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-right: 10px;
    }
    .PriceTitle {
        padding-left: 10px;
        font-size: 18px;
        text-align: right;
        line-height: 20px;
        margin-bottom: 0px;
        color: #101130;
        background-color: rgb(228, 228, 228) !important;
        height: 20px;
    }
    .Price span {
        font-size: 20px;
    }
    .Price {
        position: relative;
        top: 2px;
        padding-left: 20px;
        width: auto;
        color: #101130;
        font-family: Heebo;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: right;
        direction: rtl;
    }
    .MoreInfo {
        width: 100px;
        margin-left: 10px;
        font-size: 20px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 2px;
        padding-top: 0px;
        color: white;
        background-color: #101130;
        border-radius: 5px;
        transition: .2s;
    }
    .Divider {
        display: none;
    }
}

/* @media (max-width:360px) {
    .SingleCarContainer {
        flex: 0 0 45%;
        margin-bottom: 14px !important;
    }
    .CarsContainer {
        gap: 12px;
    }
    .Price {
        font-size: 30px !important;
    }
    .ModelName {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 4px;
        padding-left: 4px;
        font-size: 20px !important;
        line-height: 20px !important;
        margin-bottom: 5px !important;
        height: 30px;
    }
    .SmallDescription {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
        direction: rtl;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 5px;
        line-height: 15px;
        margin-top: 2px;
        color: #101130;
    }
    .MoreInfo {
        margin-top: 5px;
    }
} */