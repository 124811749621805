.SingleImage img {
    width: 100%;
}

.SingleImage {
    width: 100%;
    background-color: violet;
    font-size: 0px;
}

.GallryContainer img {
    width: 100%;
}

.TitleContainer {
    background-color: #dfdfdf !important;
}

.ItemsContainer {
 background-color: saddlebrown;
}


@media (min-width:768px) {
    .SingleImage {
        display: none !important;
    }
}

@media (max-width:768px) {
    .GallryContainer {
        display: none !important;
    }
}

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TitleContainer {
    width: 100%;
    height: 130px;
    background-color: #D3C086;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex-direction: column;
    position: relative;
}

.CloseBt {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px !important;
    font-size: 0px;
    width: 36px;
    height: 35px;
}

.CloseBt svg {
    height: 40px;
    width: 40px;
    margin: 5px 0px 0px 5px;
}

.CloseBt path {
    color: #101130;
}

.CarTitle {
    color: #101130;
    font-weight: 600;
    font-size: 30px;
    line-height: 28px;
    margin-bottom: 5px;
}

.UnderCarTitle {
    color: #101130;
    font-weight: 400;
    font-size: 20px;
    direction: rtl;
}

.GallryContainer {
    width: 100%;
    margin-bottom: 10px;
}

.CheckIcon {
    top: 3px;
    position: relative;
}

.DontHavaIcon {
    position: relative;
    top: 3px;
}

.Starts {
    position: relative;
    top: 3px;
    color: #C3922E;
}

.ItemsContainer {
    margin-bottom: 10px;
}

.MoreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.More {
    width: 90%;
    text-align: right;
    font-size: 20px;
}

@media (max-width:411px) {
    .CarTitle {
        color: #101130;
        font-weight: 600;
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 0px;
    }
    .UnderCarTitle {
        color: #101130;
        font-weight: 400;
        font-size: 20px;
        direction: rtl;
    }
}

@media (max-width:600px) {
    .MoreInfo {
        min-height: 340px;
        align-items: flex-start;
        justify-content: center;
    }
    .CloseBt svg {
        height: 30px;
        width: 30px;
        margin: 2px 0px 0px 2px;
    }
}

/* 


.MoreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.TitleAndModelContainer {
    width: 40%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 10px;
}









.More {
    width: 90%;
    text-align: right;
    font-size: 20px;
}

.ModelContainer {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.ModelAcordion {
    width: 100%;
}

.ModelAcordion .Collapsible {
    display: none !important;
}

@media (min-width:1301px) {
    .GlleryTitleMedolContainer {}
    .ItemsContainer {}
    .TitleAndModelContainer {
        height: 480px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
}

@media (max-width:1300px) and (min-width:1101px) {
    .GlleryTitleMedolContainer {}
    .ItemsContainer {}
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
}

@media (min-width:1101px) {
    .MobileView {
        display: none;
    }
}

@media (max-width:1100px) {
    .TitleContainer {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    .DesktopView {
        display: none;
    }
    .TitleAndModelContainer {
        width: 100%;
    }
    .GallryContainer {
        width: 100%;
    }
    .GlleryTitleMedolContainer {
        width: 90%;
        flex-direction: column;
    }
    .ItemsContainer {
        width: 90%;
    }
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
    .ModelAcordion {
        margin-top: 20px;
    }
} */