.FlexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #101130;
    position: absolute;

    z-index: 5000;
    width: 100vw;
    height: 100vh;
}

.Container {
    width: 95%;
    height: 90px;
    /* overflow: hidden; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ActiveBranding svg {
    width: 100px;
    cursor: pointer;
}

.RafaelLogo svg {
    width: 140px;
}

.CarShowLogo svg {
    width: 140px;
}

@media (max-width:550px) {
    .Container {
        width: 90%;
        height: 70px;
    }
    .ActiveBranding svg {
        width: 80px;
        cursor: pointer;
    }
    .RafaelLogo svg {
        width: 100px;
    }
    .CarShowLogo svg {
        width: 100px;
    }
}