.Container {
    /* background-color: violet; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
}

.disabledLink {}

@media (min-width:1500px) {
    .loader {
        position: relative;
        top: 80px;
    }
    .FilterContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        /* gap: 40px; */
        flex-direction: row-reverse;
    }
    .FilterSet1, .FilterSet2 {
        display: flex;
        /* gap: 40px; */
        flex-direction: row-reverse;
    }
}

@media (max-width:1500px) and (min-width:1000px) {
    .loader {
        position: relative;
        top: 90px;
    }
    .FilterContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        /* gap: 20px; */
        flex-direction: row-reverse;
    }
    .FilterSet1, .FilterSet2 {
        display: flex;
        /* gap: 20px; */
        flex-direction: row-reverse;
    }
}

@media (max-width:999px) {
    .loader {
        position: relative;
        top: 0px;
    }
  
    .FilterContainer {
        display: none !important;
    }
}

.GlleryTitleMedolContainer {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    min-height: 100% !important;
}

.ItemsContainer {
    margin-top: 20px;
}

.GallryContainer {
    width: 100%;
    position: relative;
    height: auto;
    min-height: 100% !important;
}

.TitleAndModelContainer {
    width: 40%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 10px;
}

.TitleContainer {
    width: 100%;
    height: 40%;
    background-color: #D3C086;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    flex-direction: column;
}

.CarTitle {
    color: #101130;
    font-weight: 600;
    font-size: 30px;
}

.UnderCarTitle {
    color: #101130;
    font-weight: 400;
    font-size: 20px;
    direction: rtl;
}

.CheckIcon {
    top: 3px;
    position: relative;
}

.DontHavaIcon {
    position: relative;
    top: 3px;
}

.FlexContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.SideBySideContainer {
    margin-top: 30px;
    display: flex;
    width: 80%;
    /* gap: 20px; */
}

.CarContainer {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
}

.Compare {
    margin-top: 20px;
    border-radius: 5px;
    background-color: #C3922E;
    padding: 10px;
    color: white;
    font-size: 24px !important;
    padding-bottom: 12px;
    transition: .3s;
}

.CompareDisable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    /*
      Introduced in Internet Explorer 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #c3912e4f;
    padding: 10px;
    color: white;
    font-size: 24px !important;
    padding-bottom: 12px;
    transition: .3s;
}

.Compare:hover {
    background-color: #9F7621;
    transition: .3s;
}

@media (max-width:768px) {
    .Compare {
        margin-top: 25px;
        margin-bottom: 20px;
    }
    .SideBySideContainer {
        width: 95%;
        /* gap: 10px; */
        margin-top: 10px;
    }
}

.SearchCar {
    background-color: saddlebrown;
}