.Title {
    font-size: 40px;
    margin-top: 120px;
    margin-bottom: 20px;
}

.TitleApprove {
    color: #a37a29;
    font-size: 40px;
    margin-top: 120px;
    margin-bottom: 20px;
}

.Title2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 0px;
}

.IconAddContainer {
    font-size: 40px;
    margin-bottom: 20px;
    color: #C3922E;
    transition: .2s;
}

.IconAdd {
    cursor: pointer;
}

.IconAdd:hover {
    color: #a37a29;
    transition: .2s;
}

.MuiFormLabel-root.Mui-focused {
    color: red !important;
}

.SiteAndAdminContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
}

.TitleSelect {
    font-size: 24px;
    font-family: 'Heebo';
    margin-top: 20px;
    margin-bottom: 5px;
    color: #101130;
}

.admin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
}

.AddUser {
    background-color: #a37a29;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 12px;
    color: white;
    font-size: 24px;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.AddUser:hover {
    background-color: #101130;
    transition: .2s;
}

.Err {
    font-size: 18px;
    margin-top: 10px;
    color: red;
    font-weight: 600;
}