.Collapsible {
  background-color: #F6F6F6;
  width: 100%;
}

.PriceTopAcordion .Collapsible__trigger {
  display: none !important;
}

.PriceTopAcordion .Collapsible__contentInner {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  background-color: #D3C086;
}

.PriceTopAcordion .divder_top {
  display: none !important;
}

.priceHover{
  color: #101130;
 
}
.priceHover:hover {
  background-color: #D3C086 !important;
}

.Collapsible__contentInner {
  /* padding: 20px; */
  /* border: 1px solid #ebebeb; */
  border-top: 0;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  cursor: pointer;
  position: relative;
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  /* border: 1px solid white; */
  padding: 4px 55px 7px 7px;
  background: #101130;
  color: white;
  text-align: right;
  font-size: 24px;
  font-family: 'Assistant', sans-serif;
  overflow: hidden;
}

.Collapsible__trigger:after {
  font-family: 'FontAwesome';
  content: '\f107';
  position: absolute;
  left: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
  font-size: 25px;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(-180deg);
}

.Collapsible__trigger.is-disabled {
  /* opacity: 0.5;
  background-color: grey; */
}

.is-disabled.is-open:after {
  display: none !important;
}

.CustomTriggerCSS {
  /* background-color: lightcoral; */
  /* transition: background-color 50ms ease; */
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  /* background-color: #CBB700; */
  color: black;
}

.ModelContainer {
  width: 100%;
  height: 100%;
}

.ModelContainer .Collapsible .Collapsible__trigger:before {
  content: "";
  display: block;
  height: 45px;
  width: 45px;
  background-size: 45px 45px;
  background-image: url('http://rafael-car-show.co.il/db/wp-content/uploads/icons/model_icon.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: -1px;
  right: -1px;
}

.EnginContainer .Collapsible .Collapsible__trigger:before {
  content: "";
  display: block;
  height: 45px;
  width: 45px;
  background-size: 45px 45px;
  background-image: url('http://rafael-car-show.co.il/db/wp-content/uploads/icons/engin.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: -1px;
  right: -1px;
}

.SaftyContainer .Collapsible .Collapsible__trigger:before {
  content: "";
  display: block;
  height: 45px;
  width: 45px;
  background-size: 45px 45px;
  background-image: url('http://rafael-car-show.co.il/db/wp-content/uploads/icons/safty.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: -1px;
  right: -1px;
}

.SizesContainer .Collapsible .Collapsible__trigger:before {
  content: "";
  display: block;
  height: 45px;
  width: 45px;
  background-size: 45px 45px;
  background-image: url('http://rafael-car-show.co.il/db/wp-content/uploads/icons/sizes.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: -1px;
  right: -1px;
}

.MultimediaContainer .Collapsible .Collapsible__trigger:before {
  content: "";
  display: block;
  height: 45px;
  width: 45px;
  background-size: 45px 45px;
  background-image: url('http://rafael-car-show.co.il/db/wp-content/uploads/icons/multimedia.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: -1px;
  right: -1px;
}

.ComfortContainer .Collapsible .Collapsible__trigger:before {
  content: "";
  display: block;
  height: 45px;
  width: 45px;
  background-size: 45px 45px;
  background-image: url('http://rafael-car-show.co.il/db/wp-content/uploads/icons/comfort.svg');
  background-repeat: no-repeat;
  position: absolute;
  top: -1px;
  right: -1px;
}