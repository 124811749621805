td {
    direction: ltr !important;
}

.CarRequestsContainer {
    display: flex;
    flex-direction: column;
}

.FilterContainer {
    margin-bottom: 20px;
}

@media (min-width:1201px) {

    .AdminHeaderTab:hover {
        background-color: #a37a29;
        transition: .2s;
    }
    .AdminHeaderTabActive {
        margin-left: 10px;
        margin-right: 10px;
        padding: 4px 10px 8px 10px;
        border-radius: 5px;
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        color: white;
        background-color: #a37a29;
    }
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .Title {
        font-size: 30px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .InputContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 30px;
        font-size: 20px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 20px;
        width: 250px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .Table {
        direction: rtl;
    }
    .Table {
        /* background-color: red; */
    }
    .Thead {}
    .Tr {
        height: 50px;
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        height: 50px;
        background-color: #F0F5F8;
        color: #101130;
        font-size: 20px;
        text-align: right;
        transition: .2s;
    }
    .TrData td {
        padding-right: 20px;
        padding-left: 50px;
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}

@media (max-width:1200px) and (min-width:768px) {
    .AdminHeaderLinks {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        cursor: pointer;
        padding-bottom: 15px;
        margin-top: 10px;
    }
    .AdminHeaderTab {
        margin-left: 5px;
        margin-right: 5px;
        padding: 7px 10px 8px 10px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 400;
        cursor: pointer;
        color: white;
        background-color: #101130;
        transition: .2s;
    }
    .AdminHeaderTab:hover {
        background-color: #a37a29;
        transition: .2s;
    }
    .AdminHeaderTabActive {
        margin-left: 5px;
        margin-right: 5px;
        padding: 4px 10px 8px 10px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 400;
        cursor: pointer;
        color: white;
        background-color: #a37a29;
    }
    .InputContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 30px;
        font-size: 20px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 20px;
        width: 250px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .TableContainer {
        width: 90%;
    }
    .Title {
        font-size: 40px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .Table {
        direction: rtl;
    }
    .Table {
        /* background-color: red; */
    }
    .Thead {}
    .Tr {
        height: 50px;
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        height: 50px;
        background-color: #F0F5F8;
        color: #101130;
        font-size: 20px;
        text-align: right;
        transition: .2s;
        line-height: 20px;
    }
    .TrData td {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}

@media (max-width:767px) and (min-width:400px) {
    .AdminHeaderLinks {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        cursor: pointer;
        padding-bottom: 15px;
        margin-top: 10px;
    }
    .AdminHeaderTab {
        margin-left: 5px;
        margin-right: 5px;
        padding: 7px 10px 8px 10px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 400;
        cursor: pointer;
        color: white;
        background-color: #101130;
        transition: .2s;
    }
    .AdminHeaderTab:hover {
        background-color: #a37a29;
        transition: .2s;
    }
    .AdminHeaderTabActive {
        margin-left: 5px;
        margin-right: 5px;
        padding: 4px 10px 8px 10px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 400;
        cursor: pointer;
        color: white;
        background-color: #a37a29;
    }
}

@media (max-width:399px) {
    .AdminHeaderLinks {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        cursor: pointer;
        padding-bottom: 15px;
        margin-top: 10px;
    }
    .AdminHeaderTab {
        margin-left: 5px;
        margin-right: 5px;
        padding: 4px 10px 5px 10px;
        border-radius: 5px;
        font-size: 16x;
        font-weight: 400;
        cursor: pointer;
        color: white;
        background-color: #101130;
        transition: .2s;
    }
    .AdminHeaderTab:hover {
        background-color: #a37a29;
        transition: .2s;
    }
    .AdminHeaderTabActive {
        margin-left: 5px;
        margin-right: 5px;
        padding: 4px 10px 5px 10px;
        border-radius: 5px;
        font-size: 16x;
        font-weight: 400;
        cursor: pointer;
        color: white;
        background-color: #a37a29;
    }
}

@media (max-width:767px) {
    .InputContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 30px;
        font-size: 20px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 20px;
        width: 250px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .TableContainer {
        width: 90%;
        margin-bottom: 50px;
    }
    .Title {
        font-size: 24px;
        margin-top: 120px;
        margin-bottom: 10px;
    }
    .Table {
        direction: rtl;
    }
    .Tr {
        /* height: 50px; */
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        /* height: 50px; */
        background-color: #F0F5F8;
        color: #101130;
        font-size: 16px;
        text-align: right;
        transition: .2s;
        line-height: 20px;
        margin-bottom: 5px;
    }
    .TrData td {
        direction: rtl;
        text-align: right !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}