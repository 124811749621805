.Container {
    /* background-color: violet; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.GlleryTitleMedolContainer {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    min-height: 100% !important;
}

.ItemsContainer {
    margin-top: 20px;
}

.GallryContainer {
    width: 100%;
    position: relative;
    /* background-color: #e2e2e2; */
    overflow: hidden;
    height: 100%;
    overflow: hidden;
}

.GallryContainer img {
    width: 100%;
    top: 5px;
    position: relative;
}

.TitleAndModelContainer {
    width: 40%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 10px;
}

.TitleContainer {
    width: 100%;
    height: 100%;
    background-color: #e2e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    flex-direction: column;
}

.CarTitle {
    color: #101130;
    font-weight: 600;
    font-size: 30px;
}

.UnderCarTitle {
    color: #101130;
    font-weight: 400;
    font-size: 20px;
    direction: rtl;
}

.CheckIcon {
    top: 3px;
    position: relative;
}

.DontHavaIcon {
    position: relative;
    top: 3px;
}

.Starts {
    position: relative;
    top: 3px;
    color: #C3922E;
}

.MoreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.More {
    width: 90%;
    text-align: right;
    font-size: 20px;
}

.ModelContainer {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.ModelAcordion {
    width: 100%;
}

.ModelAcordion .Collapsible {
    display: none !important;
}

@media (min-width:1301px) {
    .GlleryTitleMedolContainer {
        width: 1200px;
    }
    .ItemsContainer {
        width: 1200px;
    }
    .TitleAndModelContainer {
        height: 480px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 10px;
        background-color: #10113033;
        height: 1px;
        width: 90%;
    }
    .PriceTitle {
        color: #101130;
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 7px;
    }
    .BigPrice {
        color: #101130;
        font-weight: 400;
        font-size: 40px;
        line-height: 20px;
        font-weight: 600;
        direction: rtl;
    }
    .BigPrice span {
        font-size: 26px;
    }
}

@media (max-width:1300px) and (min-width:1101px) {
    .CarTitle {
        color: #101130;
        font-weight: 600;
        font-size: 30px;
        margin-top: -7px;
    }
    .TitleContainer {
        height: 50% !important;
    }
    .ModelContainer {
        height: 50% !important;
    }
    .Divider {
        margin-top: 13px;
        margin-bottom: 5px;
        background-color: #10113033;
        height: 1px;
        width: 90%;
    }
    .PriceTitle {
        color: #101130;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 5px;
    }
    .BigPrice {
        color: #101130;
        font-weight: 400;
        font-size: 35px;
        line-height: 16px;
        font-weight: 600;
        direction: rtl;
    }
    .BigPrice span {
        font-size: 21px;
    }
    .GlleryTitleMedolContainer {
        width: 1000px;
    }
    .ItemsContainer {
        width: 1000px;
    }
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
}

@media (min-width:1101px) {
    .MobileView {
        display: none;
    }
}

@media (max-width:1100px) {
    .CarTitle {
        color: #101130;
        font-weight: 600;
        font-size: 30px;
        margin-top: 5px;
    }
    .Divider {
        margin-top: 13px;
        margin-bottom: 5px;
        background-color: #10113033;
        height: 1px;
        width: 90%;
    }
    .PriceTitle {
        color: #101130;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 5px;
    }
    .BigPrice {
        color: #101130;
        font-weight: 400;
        font-size: 35px;
        line-height: 16px;
        font-weight: 600;
        direction: rtl;
        margin-bottom: 10px;
    }
    .BigPrice span {
        font-size: 21px;
    }
    .TitleContainer {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    .DesktopView {
        display: none;
    }
    .TitleAndModelContainer {
        width: 100%;
    }
    .GallryContainer {
        width: 100%;
    }
    .GlleryTitleMedolContainer {
        width: 90%;
        flex-direction: column;
    }
    .ItemsContainer {
        width: 90%;
    }
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
    .ModelAcordion {
        margin-top: 20px;
    }
}