.ItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.AcordionItem {
    direction: rtl;
    justify-content: space-between;
    display: flex;
    height: 100%;
    width: 90%;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: .2s;
}

.AcordionItem:hover {
    background-color: rgb(231, 231, 231);
    transition: .2s;
}

.Title {
    opacity: 0;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    text-align: right;
    direction: rtl;
}

.Value {
    opacity: 0;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    direction: rtl;
}

.Price {
    font-size: 26px;
    font-weight: 600;
    position: relative;
    top: -6px
}

.DividerBottom {
    border-Top: solid #1011303b;
    /* height: 10px; */
    border-width: 1px;
    width: 90%;
}

.DividerTop {
    border-bottom: solid #8b8b8b3b;
    /* height: 10px; */
    border-width: 1px;
    width: 90%;
}