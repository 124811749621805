.ItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.AcordionItem {
    direction: rtl;
    justify-content: space-between;
    display: flex;
    height: 100%;
    width: 90%;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: .2s;
}

.AcordionItem:hover {
    background-color: rgb(231, 231, 231);
    transition: .2s;
}

.Title {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    text-align: right;
    direction: rtl;
    padding-right: 18px;
}

.Value {
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    direction: rtl;
}

.Price {
    font-size: 26px;
    font-weight: 600;
    position: relative;
    top: -6px
}

.DividerBottom {
    border-Top: solid #1011303b;
    /* height: 10px; */
    border-width: 1px;
    width: 90%;
}

.DividerTop {
    border-bottom: solid #1011303b;
    /* height: 10px; */
    border-width: 1px;
    width: 90%;
}

.toolTip {
    position: absolute;
    top: 11px;
    padding-left: 4px;
    cursor: pointer;
}

.toolTip path {
    color: #D3C086;
}

.toolTipContent {
    z-index: 2000;
    font-size: 16px !important;
    width: 200px;
    text-align: right;
    direction: rtl !important;
    padding: 10px 10px 10px 10px !important;
    box-shadow: 0px 0px 2px 0px #10113023;
}