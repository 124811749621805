@import "node_modules/react-modal-video/scss/modal-video.scss";

@import "~react-image-gallery/styles/scss/image-gallery.scss";
// /* image-gallery START */

.sc-iemXMA {
  direction: ltr !important;
}

.iZNzAJ {
  direction: ltr !important;
}
.sc-iemXMA {
  display: ltr !important;
}
.jCsbxo {
  direction: ltr !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.fullscreen .vimeo_iframe iframe {
  position: relative;
  top: 0px;
}
.vimeo_iframe {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.vimeo_iframe iframe {
  width: 100%;
  height: 100%;
}

.video_holder img {
  width: 100%;
  height: 100%;
  position: relative;
}

// .tabContainer {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
// }

// .image-gallery-icon {
//   color: #BAD9EF;
// }

// @media (min-width:1024px) {
//   .image-gallery {
//       width: 45%;
//   }
//   .react-tabs {
//       width: 45%;
//   }
//   .react-tabs__tab-panel .image-gallery {
//       width: 100% !important;
//   }
//   .image-gallery-thumbnails {
//       overflow: hidden;
//       padding: 5px 0;
//       width: 100% !important;
//       background-color: white;
//   }
//   .fullscreen .image-gallery-thumbnails {
//       overflow: hidden;
//       padding: 5px 0;
//       width: 100%;
//   }
// }

// /* iPad in landscape */

// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
//   .image-gallery, .react-tabs {
//       width: 75% !important;
//       margin-top: 10px;
//   }
//   .react-tabs__tab-panel .image-gallery {
//       width: 100% !important;
//   }
// }

// /* Portrait iPad Pro */

// @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
//   .image-gallery, .react-tabs {
//       width: 75% !important;
//       margin-top: 10px;
//   }
//   .react-tabs__tab-panel .image-gallery {
//       width: 100% !important;
//   }
// }

// /* Landscape iPad Pro */

// @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
//   .image-gallery, .react-tabs {
//       width: 75%!important;
//       margin-top: 10px;
//   }
//   .react-tabs__tab-panel.image-gallery {
//       width: 100%!important;
//   }
// }

// @media (max-width:1023px) {
//   .image-gallery {
//       width: 90%!important;
//   }
//   .TabsGallery {
//       width: 100%;
//       background-color: orangered;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//   }
//   .react-tabs {
//       width: 90%;
//   }
//   .react-tabs__tab {
//       font-size: 15px;
//   }
//   .react-tabs__tab-panel .image-gallery {
//       width: 100%!important;
//   }
//   .image-gallery-thumbnails {
//       overflow: hidden;
//       padding: 5px 0;
//       width: 100% !important;
//       background-color: white;
//   }
//   .fullscreen .image-gallery-thumbnails {
//       overflow: hidden;
//       padding: 5px 0;
//       width: 100%;
//       background-color: white;
//   }
//   .fullscreen, .image-gallery {
//       background-color: white !important;
//   }
// }

// .image-gallery-play-button {
//   display: none;
// }

// .image-gallery-slide {
//   background-color: white;
// }

// .fullscreen .image-gallery-thumbnails-wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .image-gallery-slides {
//   box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
// }

.image-gallery-thumbnail {
  outline: none;
  border: 1px solid #a3a3a3;
}

// /* image-gallery END */

.GalleryPlay {
  color: rgba(255, 255, 255, 0.74) !important;
}

.play-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  font-size: 50px;
  background-color: #c3922e;
  border-radius: 100px;
  box-shadow: 0px 0px 5px 5px rgba(0, 48, 80, 0.3);
}
.play-button:hover {
  background-color: #e0a328;
}

.modal-video-close-btn {
  right: 0px !important;
}
.modal-video {
  background-color: #ffffffd3;
}

body {
  font-family: "Assistant", sans-serif;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a {
  color: #c3922e;
  font-family: "Assistant", sans-serif;
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 200px;
  /* bottom: 0; */
  margin: auto;
  width: 50px;
}

.loadermainMobile {
  position: absolute;
  left: 0;
  right: 0;
  top: 130px;
  /* bottom: 0; */
  margin: auto;
  width: 50px;
}

.number {
  font-family: "Heebo", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MainVideo {
  background-color: red;
  width: 100%;
  font-size: 0px;
}

.MainVideo img {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .FamilySparator {
    display: none !important;
  }
}

.sc-bqGHjH {
  background-color: #0f102f !important;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sc-iCoHVE {
  width: 16px !important;
  height: 16px !important;
  border-right: solid 3px !important;
  border-right-color: white !important;
  border-bottom: solid 3px !important;
  border-bottom-color: white !important;
}

// react-styled-select
// More css at header.module.css  --- .SelectTeam

.eGgxSq,
.elpjoh {
  left: 2px;
  bottom: -3px;
}

.gKqrex,
.fOgyfe {
  left: 2px;
  bottom: 3px;
}

.sc-bkbjAj {
  font-size: 22px;
  font-weight: 600;
  padding-right: 10px;
}
.sc-iqAbSa {
  border: none;
}
.sc-fnVYJo {
  height: 32px;
}
.dNOEdU {
  font-size: 16px;
  font-weight: 600;
}
.jCsbxo {
  font-size: 16px;
  font-weight: 600;
}

// fiXForSIDE_BY_SIDE START
@media (max-width: 768px) and (min-width: 541px) {
  .sidebyside .sc-bkbjAj {
    width: 150px;
  }
}
@media (max-width: 540px) and (min-width: 411px) {
  .sidebyside .sc-bkbjAj {
    width: 120px;
  }
}
@media (max-width: 410px) {
  .sidebyside .sc-bkbjAj {
    width: 100px;
  }
}
// fiXForSIDE_BY_SIDE END

@media (max-width: 414px) {
  .sc-bqGHjH {
    background-color: #0f102f !important;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sc-iCoHVE {
    width: 10px !important;
    height: 10px !important;
    border-right: solid 3px !important;
    border-right-color: white !important;
    border-bottom: solid 3px !important;
    border-bottom-color: white !important;
  }

  .eGgxSq,
  .elpjoh {
    left: 2px;
    bottom: -3px;
  }

  .gKqrex,
  .fOgyfe {
    left: 2px;
    bottom: 3px;
  }

  .sc-bkbjAj {
    font-size: 18px;
    font-weight: 600;
    padding-right: 10px;
  }
  .sc-iqAbSa {
    border: none;
  }
  .sc-fnVYJo {
    height: 32px;
  }
  .dNOEdU {
    font-size: 16px;
    font-weight: 600;
  }
  .jCsbxo {
    font-size: 16px;
    font-weight: 600;
  }
}
// react-styled-select END

@media (max-width: 768px) {
  .SideBySideContainer .Collapsible__trigger {
    padding-right: 5px;
    font-size: 18px;
  }
  .SideBySideContainer .Collapsible__trigger::before {
    display: none !important;
  }
  .Collapsible__trigger:after {
    top: 5px;
  }
}
