@media (min-width:601px) {
    .LoginHeader {
        position: absolute;
        z-index: 4000;
        width: 100%;
        text-align: left;
        padding-top: 10px;
    }
}

@media (max-width:600px) {
    .LoginHeader {
        position: relative;
        z-index: 4000;
        width: 100%;
        text-align: left;
        padding-top: 10px;
        background-color: #000614;
    }
}


.container {
    display: flex;
    padding-left: 20px;
}

.space {
    color: white;
    margin-right: 7px;
    margin-left: 7px;
    position: relative;
    top: 2px
}

.logout {
    color: white;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

.logout:hover {
    color: #C3922E;
}

.id {
    color: white;
    font-size: 20px;
}

.Number {
    font-size: 18px !important;
}