.HiddenTableForDownload {
    display: none;
}

.download-table-xls-button {
    margin-bottom: 20px;
    background-color: #C3922E;
    border: none;
    padding: 10px 20px 10px 20px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 50px;
}

.download-table-xls-button:hover {
    transition: 0.2s;
    background-color: #a37a29 !important;
}

@media (min-width:1201px) {}

@media (max-width:1200px) and (min-width:768px) {}

@media (max-width:767px) {
    .pivoted {
        /* background-color: tomato; */
        text-align: right !important;
        width: 95%;
    }
    .responsiveTable tbody tr {
        float: right;
        width: 100%;
        text-align: right;
    }
    .responsiveTable td.pivoted {
        text-align: right !important;
    }
    .responsiveTable td {}
    .responsiveTable td .tdBefore {
        display: flex !important;
        position: relative;
        /* background-color: violet; */
        left: 0 !important;
        width: 99% !important;
        text-align: right;
        width: 100%;
        justify-content: flex-end;
    }
}