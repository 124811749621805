.SingleImage img {
    width: 100%;
}

.SingleImage {
    width: 100%;
    background-color: white;
    font-size: 0px;
}

@media (min-width:531px) {
    .supply {
        background-color: #D3C086;
        text-align: center;
        font-size: 22px;
        position: relative;
        bottom: -20px;
        padding-top: 3px;
        padding-bottom: 5px;
        font-weight: 600;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width:530px) {
    .supply {
        background-color: #D3C086;
        text-align: center;
        font-size: 20px;
        position: relative;
        bottom: -20px;
        padding-top: 5px;
        padding-bottom: 7px;
        font-weight: 600;
        line-height: 20px;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width:768px) {
    .SingleImage {
        display: none !important;
    }
}

@media (max-width:768px) {
    .GallryContainer {
        display: none !important;
    }
}

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TitleContainer {
    width: 100%;
    height: 130px;
    background-color: #D3C086;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex-direction: column;
    position: relative;
}

.CloseBt {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px !important;
    font-size: 0px;
    width: 36px;
    height: 35px;
}

.CloseBt svg {
    height: 40px;
    width: 40px;
    margin: 5px 0px 0px 5px;
}

.CloseBt path {
    color: #101130;
}

.CarTitle {
    color: #101130;
    font-weight: 600;
    font-size: 30px;
    line-height: 28px;
    margin-bottom: 5px;
}

.UnderCarTitle {
    color: #101130;
    font-weight: 400;
    font-size: 21px;
    direction: rtl;
}

.GallryContainer {
    width: 100%;
    margin-bottom: 10px;
}

.CheckIcon {
    top: 3px;
    position: relative;
}

.DontHavaIcon {
    position: relative;
    top: 3px;
}

.Starts {
    position: relative;
    top: 3px;
    color: #C3922E;
}

.ItemsContainer {
    margin-bottom: 10px;
}

.MoreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.More {
    direction: rtl;
    width: 90%;
    text-align: right;
    font-size: 20px;
}

@media (max-width:411px) {
    .CarTitle {
        color: #101130;
        font-weight: 600;
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 0px;
    }
    .UnderCarTitle {
        color: #101130;
        font-weight: 400;
        font-size: 20px;
        direction: rtl;
    }
}

@media (max-width:600px) {
    .MoreInfo {
        min-height: 340px;
        align-items: flex-start;
        justify-content: center;
    }
    .CloseBt svg {
        height: 30px;
        width: 30px;
        margin: 2px 0px 0px 2px;
    }
}

@media (min-width:1701px) {
    .LinkFlexContainer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }
    .LinkContainer {
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }
    .LinkText {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        /* padding-right: 30px; */
        margin-left: 10px;
    }
    .LinkContainer a {
        font-size: 18px;
        top: -5px;
        position: relative;
    }
}

@media (max-width:1700px) and (min-width:769px) {
    .LinkFlexContainer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }
    .LinkContainer {
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }
    .LinkText {
        width: 70%;
        text-align: right;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        /* padding-right: 30px; */
        margin-left: 10px;
    }
    .LinkContainer a {
        width: 30%;
        font-size: 18px;
        top: -5px;
        position: relative;
    }
}

@media (max-width:768px) {
    .LinkFlexContainer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }
    .LinkContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .LinkText {
        width: 100%;
        line-height: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        /* padding-right: 30px; */
        /* margin-left: 10px; */
    }
    .LinkContainer a {
        width: 100%;
        font-size: 20px;
        top: -5px;
        position: relative;
        text-align: center;
    }
}