@media (min-width:769px) {
    .PriceTitleClass {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        top: -3px;
        height: 40px;
    }
    .PriceValueClass {
        display: flex;
        align-items: center;
    }
    .TaxText {
        font-size: 16px;
        margin-top: -5px;
    }
    .CheckIcon {
        display: none !important;
    }
    .ItemContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    .AcordionItem {
        direction: rtl;
        justify-content: space-between;
        display: flex;
        height: 100%;
        width: 95%;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: .2s;
    }
    .AcordionItem:hover {
        background-color: rgb(231, 231, 231);
        transition: .2s;
    }
    .Title {
        font-size: 20px;
        font-weight: 600;
        /* width: 55%; */
        text-align: right;
    }
    .Value {
        font-size: 20px;
        font-weight: 400;
        /* width: 70%; */
        text-align: left;
    }
    .DividerBottom {
        border-Top: solid #1011303b;
        border-width: 1px;
        width: 95%;
    }
    .DividerTop {
        border-bottom: solid #1011303b;
        border-width: 1px;
        width: 95%;
    }
    .Price {
        font-size: 26px;
        font-weight: 600;
        position: relative;
        top: -4px;
    }
    .ItemsContainer {
        margin-bottom: 10px;
    }
}

@media (max-width:768px) and (min-width:601px) {
    .PriceTitleClass {
        min-height: 0px !important;
        margin-bottom: 10px;
    }
    .PriceValueClass {
        margin-bottom: 7px;
        min-height: 0px !important;
    }
    .PriceTitleClass {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 35px;
    }
    .TaxText {
        font-size: 16px;
        margin-top: -5px;
        margin-bottom: 5px;
    }
    .CheckIcon {
        display: none !important;
    }
    .ItemContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    .AcordionItem {
        direction: rtl;
        flex-direction: column;
        justify-content: center;
        display: flex;
        height: 100%;
        width: 95%;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: .2s;
        /* background-color: red; */
    }
    .AcordionItem:hover {
        background-color: rgb(231, 231, 231);
        transition: .2s;
    }
    .Title {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }
    .Value {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
    .DividerBottom {
        border-Top: solid #1011303b;
        border-width: 1px;
        width: 95%;
    }
    .DividerTop {
        border-bottom: solid #1011303b;
        border-width: 1px;
        width: 95%;
    }
    .Price {
        font-size: 26px;
        font-weight: 600;
        position: relative;
        top: -6px
    }
    .ItemsContainer {
        margin-bottom: 10px;
    }
}

@media (max-width:600px) {
    .PriceTitleClass {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        text-align: center;
    }
    .PriceValueClass {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        min-height: 0px !important;
        margin-top: -3px;
        margin-bottom: 10px;
    }
    .TaxText {
        width: 100%;
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .CheckIcon {
        display: none !important;
    }
    .ItemContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    .AcordionItem {
        direction: rtl;
        flex-direction: column;
        justify-content: center;
        display: flex;
        height: 100%;
        width: 95%;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: .2s;
        /* background-color: red; */
    }
    .AcordionItem:hover {
        background-color: rgb(231, 231, 231);
        transition: .2s;
    }
    .Title {
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;
        justify-content: center;
        font-size: 20px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        min-height: 40px;
        /* background-color: saddlebrown; */
    }
    .Value {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        font-size: 20px;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        min-height: 40px;
        /* background-color: royalblue; */
    }
    .DividerBottom {
        border-Top: solid #1011303b;
        border-width: 1px;
        width: 95%;
    }
    .DividerTop {
        border-bottom: solid #1011303b;
        border-width: 1px;
        width: 95%;
    }
    .Price {
        font-size: 26px;
        font-weight: 600;
        position: relative;
        top: -6px
    }
    .ItemsContainer {
        margin-bottom: 10px;
    }
}