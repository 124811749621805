.GlleryTitleMedolContainer {
    width: 90%;
}

.TitleContainer {
    width: 100%;
    height: 50px;
    /* background-color: #D3C086; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
}

.CarTitle {
    color: #c3922e;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
}

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SelectArea {
    /* background-color: #F0F5F8; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.SelectManufacturer {
    width: 90%;
    direction: rtl;
}

.SelectTeam {
    margin-bottom: 20px;
    text-align: right;
    --styled-select-placeholder__color: #0F102F;
    --styled-select-placeholder__font-family: 'Assistant', sans-serif;
    --styled-select-placeholder__font-size: 22px;
    --styled-select-placeholder__font-weight: 600;
    --styled-select-placeholder__padding: 0px 10px 0px 0px;
    --styled-select__color: #0F102F;
    --styled-select__font-size: 22px;
    --styled-select-arrow-zone__width: 60px;
    --styled-select-arrow-zone__width: 50px;
    --styled-select-arrow__color: white;
    --styled-select-control__border-color: #C6C6C6;
    --styled-select-value-label__font-family: 'Assistant', sans-serif;
}

.SelectTeam *:focus {
    outline: none;
}

.UnderCarTitle {
    color: #101130;
    font-weight: 400;
    font-size: 20px;
    direction: rtl;
}

.GallryContainer {
    width: 100%;
    margin-bottom: 10px;
}

.CheckIcon {
    top: 3px;
    position: relative;
}

.DontHavaIcon {
    position: relative;
    top: 3px;
}

.Starts {
    position: relative;
    top: 3px;
    color: #C3922E;
}

.ItemsContainer {
    margin-bottom: 10px;
}

.MoreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.More {
    width: 90%;
    text-align: right;
    font-size: 20px;
}

@media (max-width:600px) {
    .MoreInfo {
        min-height: 340px;
        align-items: flex-start;
        justify-content: center;
    }
}

@media (max-width:769px) {
    .CarTitle {
        color: #c3922e;
        font-weight: 600;
        font-size: 28px;
        line-height: 28px;
    }
}

@media (max-width:410px) {
    .CarTitle {
        color: #c3922e;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
    }
    .TitleContainer {
        width: 100%;
        height: 40px;
        /* background-color: #D3C086; */
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        flex-direction: column;
    }

    
}

@media (max-width:411px) {
    .SelectArea {
        /* background-color: #F0F5F8; */
        /* padding-top: 50px;
        padding-bottom: 50px; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .SelectTeam {
        margin-bottom: 20px;
        text-align: right;
        --styled-select-placeholder__color: #0F102F;
        --styled-select-placeholder__font-family: 'Assistant', sans-serif;
        --styled-select-placeholder__font-size: 20px;
        --styled-select-placeholder__font-weight: 600;
        --styled-select-placeholder__padding: 0px 10px 0px 0px;
        --styled-select__color: #0F102F;
        --styled-select__font-size: 10px;
        --styled-select-arrow-zone__width: 10px;
        --styled-select-arrow-zone__width: 20px;
        --styled-select-arrow__color: white;
        --styled-select-control__border-color: #C6C6C6;
        --styled-select-value-label__font-family: 'Assistant', sans-serif;
    }
}

/* 


.MoreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.TitleAndModelContainer {
    width: 40%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 10px;
}









.More {
    width: 90%;
    text-align: right;
    font-size: 20px;
}

.ModelContainer {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.ModelAcordion {
    width: 100%;
}

.ModelAcordion .Collapsible {
    display: none !important;
}

@media (min-width:1301px) {
    .GlleryTitleMedolContainer {}
    .ItemsContainer {}
    .TitleAndModelContainer {
        height: 480px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
}

@media (max-width:1300px) and (min-width:1101px) {
    .GlleryTitleMedolContainer {}
    .ItemsContainer {}
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
}

@media (min-width:1101px) {
    .MobileView {
        display: none;
    }
}

@media (max-width:1100px) {
    .TitleContainer {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    .DesktopView {
        display: none;
    }
    .TitleAndModelContainer {
        width: 100%;
    }
    .GallryContainer {
        width: 100%;
    }
    .GlleryTitleMedolContainer {
        width: 90%;
        flex-direction: column;
    }
    .ItemsContainer {
        width: 90%;
    }
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
    .ModelAcordion {
        margin-top: 20px;
    }
} */