.FlexContainer {
    display: flex;
    justify-content: flex-end;
    direction: ltr;
}

.Container {
    display: flex;
    position: relative;
    top: 8px;
    flex-direction: row-reverse;
    direction: rtl;
}

@media (min-width:551px) {
    .Container {
        top: 5px;
    }
    .descriptionLeft {
        position: relative;
        font-size: 12px;
        font-weight: 600;
        padding-right: 4px;
        color: #101130;
    }
    .descriptionRight {
        position: relative;
        font-size: 12px;
        font-weight: 600;
        padding-left: 4px;
        color: #101130;
    }
    .Item {
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 2px 2px 2px;
        margin-left: 3px;
        color: white;
        font-size: 12px;
        font-weight: 600;
    }
}

@media (max-width:550px) {
    .Item {
        display: none;
    }
}

@media (max-width:780px) {
    .descriptionLeft {
        display: none;
    }
    .descriptionRight {
        display: none;
    }
}

.Item0 {
    background-color: #c00000;
}

.Item1 {
    background-color: #e46c0b;
}

.Item2 {
    background-color: #febf00;
}

.Item3 {
    background-color: #33af8a;
}

.Item4 {
    background-color: #31859d;
}

.Item5 {
    background-color: #268cca;
}

.Item6 {
    background-color: #0170c1;
}

.Item7 {
    background-color: #0373fb;
}

.Item8 {
    background-color: #0373fb;
}

.Selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    position: relative;
    top: -5px;
    font-size: 18px;
    padding-bottom: 2px;
    color: white;
}

@media (min-width:551px) {
    .Selected {
        box-shadow: 0px 0px 3px #1f1f1f;
    }
}

@media (max-width:550px) {
    .Container {
        width: 100%;
        justify-content: flex-start !important;
    }
    .Selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        position: relative;
        top: -8px;
        font-size: 18px;
        padding-bottom: 2px;
        color: white;
    }
}

@media (max-width:768px) {
    .FlexContainerSideBySide .Selected {
        width: 100% !important;
        top: 0px;
    }
}

@media (max-width:1170px) {
    .FlexContainerSideBySide .Item {
        display: none;
    }
    .FlexContainerSideBySide .Selected {
        display: flex !important;
        width: 40px !important;
    }
}