.AllImagesPreLoader {
    position: absolute !important;
    opacity: 0 !important;
    z-index: -5000 !important;
    display: none !important;
}
.ContactInfo{
    color: white;
    font-size: 24px;
    line-height: 24px;
    margin-top: 60px;
    direction: rtl;
}
.DemoText p{
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 30px;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {
    /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.TopOfFrame {
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.DemoText{
    font-size: 26px;
    color:white;
}

.TopOfFrame .Left {
    width: 100%;
    border-style: solid;
    border-color: white;
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 0;
    border-bottom-width: 0;
    border-top-left-radius: 10px;
    height: 20px;
}

.TopOfFrame .Center {
    height: 20px;
 
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    top: -22px;
}

.TopOfFrame .Center img {
    width: 200px;
    position: relative;
    top:-5px;
}

.TopOfFrame .Right {
    width: 100%;
    border-style: solid;
    border-color: white;
    border-left-width: 0px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0;
    border-top-right-radius: 10px;
    height: 20px;
}

.Approve {
    color: white;
    font-size: 24px;
    font-weight: 300;
    margin-top: 0px;
}

.ApproveComment {
    transition: .2s;
    margin-top: 15px;
    margin-bottom: 5px;
    color: red;
    position: relative;
}

.FlexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ButtonContainer:hover {
    background-color: #9b701b;
}

.ButtonContainer {
    animation: fadein_b .2s;
    -moz-animation: fadein_b .2s;
    /* Firefox */
    -webkit-animation: fadein_b .2s;
    /* Safari and Chrome */
    -o-animation: fadein_b .2s;
    /* Opera */
}

.ButtonContainer, .NonActiveButtonButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-style: none;
    border-color: white;
    background-color: #C3922E;
    border-radius: 100px;
    border-width: 1px;
    width: 250px;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@keyframes fadein_b {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein_b {
    /* Firefox */
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein_b {
    /* Safari and Chrome */
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein_b {
    /* Opera */
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.GoToSiteButton {
    /* color: #C3922E; */
    color: white;
}

.NonActiveButton {
    opacity: 0.5;
    /* background-color: red; */
}

.NonActiveButtonButtonContainer {
    /* background-color: #f7d89b; */
}

.ButtonContainer svg, .NonActiveButtonButtonContainer svg {
    position: relative;
    top: 1px
}

.GoToSiteButton {
    font-size: 28px;
    position: relative;
    top: -2px;
    padding-right: 10px;
    font-weight: 500;
}

@media (min-width:1101px) {
    .VideoContainer {
        position: absolute;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #101130;
        height: 100vh;
        width: 100vw;
        flex-direction: column-reverse;
    }
    .UnMute {
        position: absolute;
        width: 100%;
        top: -40px;
        cursor: pointer;
        margin-bottom: 20px;
        color: white;
    }
    .VimeoContainer {
        position: relative;
        width: 1000px;
    }
}

@media (max-width:1100px) {
    .VideoContainer {
        position: absolute;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #101130;
        height: 100vh;
        width: 100vw;
        flex-direction: column-reverse;
    }
    .UnMute {
        position: absolute;
        width: 100%;
        top: -40px;
        cursor: pointer;
        margin-bottom: 20px;
        color: white;
    }
    .VimeoContainer {
        position: relative;
        width: 90%;
    }
}

@media (min-width:501px) {
    .idTitle {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 28px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .InputContainer input {
        width: 250px;
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 40px;
    }
    .InputContainer input:focus {
        outline: none;
    }
    .ApproveReading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        right: 12px;
        margin-bottom: 10px;
        height: 30px;
    }
    .RadioButton {
        position: relative;
        top: 5px;
        right: 0;
        margin-left: 10px;
        background-color: royalblue;
    }
    .BackgroundImage {
        width: 100%;
        position: absolute;
        z-index: -1000;
    }
    .BackgroundImage img {
        width: 100%;
        height: 100vh;
    }
    .CarShowLogo {
        padding-top: 100px;
    }
    .MainContainer {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        /* background-color: violet; */
        display: flex;
        justify-content: center;
    }
    .Frame {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        padding-top: 100px;
        padding-bottom: 100px;
        width: 1100px;
        /* height: 500px; */
        flex-direction: column;
        animation: fadein 2s;
        -moz-animation: fadein 2s;
        /* Firefox */
        -webkit-animation: fadein 2s;
        /* Safari and Chrome */
        -o-animation: fadein 2s;
        /* Opera */
    }
    .BigTitle {
        direction: rtl;
        color: #C3922E;
        font-size: 35px;
        padding-top: 30px;
        padding-bottom: 3px;
    }
    .Title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .paragrph1 {
        text-align: right;
        font-size: 22px;
        line-height: 26px;
    }
    .BodyText {
        direction: rtl;
        color: white;
        font-size: 18px;
        font-weight: 300;
        text-align: right;
    }
    .TabText {
        font-size: 18px;
        direction: rtl;
    }
    .TabText a {
        font-weight: 600;
    }
    .TabItem {
        margin-top: 10px;
        display: flex;
    }
    .Bullet {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Divider {
        background-color: #C3922E;
        width: 2px;
        height: 80%;
        top: 2px;
        position: relative;
    }
    .CloserText {
        text-align: center;
        margin-top: 30px;
        font-weight: 600;
    }
    .RestOfFrame {
        border-style: solid;
        border-color: white;
        border-left-width: 1px;
        border-top-width: 0px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
    }
}

@media (max-width:500px) and (min-width:411px) {
    .Center svg {
        width: 150px;
    }
    .idTitle {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 28px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .InputContainer input {
        width: 250px;
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 15px;
    }
    .InputContainer input:focus {
        outline: none;
    }
    .ApproveReading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        right: 12px;
        margin-bottom: 5px;
    }
    .RadioButton {
        position: relative;
        top: 5px;
        right: 0;
        margin-left: 10px;
        background-color: royalblue;
    }
    .BackgroundImage {
        width: 100%;
        position: absolute;
        z-index: -1000;
    }
    .BackgroundImage img {
        width: 100%;
        height: 800px;
    }
    .TopOfFrame .Left, .TopOfFrame .Right {
        border: none;
    }
    .CarShowLogo {
        padding-top: 20px;
    }
    .CarShowLogo svg {
        width: 110px;
    }
    .MainContainer {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        /* height: 100vh; */
        /* background-repeat: no-repeat; */
        /* background-size: 100% 100%; */
        display: flex;
        justify-content: center;
        /* overflow: hidden; */
        /* background-color: tomato; */
        /* padding-bottom: 50px; */
    }
    .Frame {
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        padding-top: 100px;
        /* padding-bottom: 100px; */
        width: 900px;
        /* height: 500px; */
        flex-direction: column;
        animation: fadein 2s;
        -moz-animation: fadein 2s;
        /* Firefox */
        -webkit-animation: fadein 2s;
        /* Safari and Chrome */
        -o-animation: fadein 2s;
        /* Opera */
    }
    .BigTitle {
        direction: rtl;
        color: #C3922E;
        font-size: 22px;
        padding-top: 40px;
        padding-bottom: 2px;
    }
    .Title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .paragrph1 {
        text-align: right;
        font-size: 18px;
        line-height: 22px;
    }
    .BodyText {
        direction: rtl;
        color: white;
        font-size: 18px;
        font-weight: 300;
        text-align: right;
    }
    .TabText {
        font-size: 18px;
        direction: rtl;
        line-height: 20px;
    }
    .TabText a {
        font-weight: 600;
    }
    .TabItem {
        margin-top: 10px;
        display: flex;
    }
    .Bullet {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Divider {
        background-color: #C3922E;
        width: 2px;
        height: 100%;
        top: 2px;
        position: relative;
    }
    .CloserText {
        text-align: center;
        margin-top: 30px;
        font-weight: 600;
    }
    .RestOfFrame {
        border-style: solid;
        border-color: white;
        border: none;
        border-left-width: 1px;
        border-top-width: 0px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }
}

@media (max-width:410px) {
    .Center svg {
        width: 120px;
    }
    .idTitle {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 28px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .InputContainer input {
        width: 250px;
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .InputContainer input:focus {
        outline: none;
    }
    .ApproveReading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        right: 12px;
        margin-bottom: 5px;
        height: 30px;
    }
    .RadioButton {
        position: relative;
        top: 5px;
        right: 0;
        margin-left: 10px;
        background-color: royalblue;
    }
    .BackgroundImage {
        width: 100%;
        position: absolute;
        z-index: -1000;
    }
    .BackgroundImage img {
        width: 100vw;
        height: 700px;
    }
    .TopOfFrame .Left, .TopOfFrame .Right {
        border: none;
    }
    .CarShowLogo {
        padding-top: 17px;
    }
    .CarShowLogo svg {
        width: 150px;
    }
    .MainContainer {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        /* height: 100vh; */
        /* background-repeat: no-repeat; */
        /* background-size: 100% 100%; */
        display: flex;
        justify-content: center;
        /* overflow: hidden; */
    }
    .Frame {
        margin-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        padding-top: 25px;
        /* padding-bottom: 100px; */
        width: 900px;
        /* height: 500px; */
        flex-direction: column;
        animation: fadein 2s;
        -moz-animation: fadein 2s;
        /* Firefox */
        -webkit-animation: fadein 2s;
        /* Safari and Chrome */
        -o-animation: fadein 2s;
        /* Opera */
    }
    .BigTitle {
        direction: rtl;
        color: #C3922E;
        font-size: 20px;
        padding-top: 0px;
        padding-bottom: 2px;
    }
    .Title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .paragrph1 {
        text-align: right;
        font-size: 20px;
        line-height: 22px;
    }
    .BodyText {
        direction: rtl;
        color: white;
        font-size: 20px;
        font-weight: 300;
        text-align: right;
    }
    .TabText {
        font-size: 18px;
        direction: rtl;
        line-height: 20px;
    }
    .TabText a {
        font-weight: 600;
    }
    .TabItem {
        margin-top: 10px;
        display: flex;
    }
    .Bullet {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Divider {
        background-color: #C3922E;
        width: 2px;
        height: 100%;
        top: 2px;
        position: relative;
    }
    .CloserText {
        text-align: center;
        margin-top: 30px;
        font-weight: 600;
    }
    .RestOfFrame {
        border-style: solid;
        border-color: white;
        border: none;
        border-left-width: 1px;
        border-top-width: 0px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }
}