td {
    direction: ltr !important;
}

.FlexContainer {
    margin-bottom: 70px;
}

.chaeckbox {
    position: relative;
    top: -5px;
}

.ConfirmDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: fixed;
    width: 450px;
    height: 250px;
    z-index: 1100;
    top: 300px;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.514) 0px 0px 30px;
}

.TitleDelete {
    direction: rtl;
    font-size: 22px;
}

.BtContainer {
    display: flex;
    flex-direction: row-reverse;
}

.BtDelete {
    margin-left: 5px;
    margin-right: 5px;
    background-color: rgb(153, 51, 65);
    padding: 8px 0px 10px 0px;
    width: 120px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtUpdate, .BtUpdateDisable {
    margin-left: 5px;
    margin-right: 5px;
    background-color: rgb(17, 110, 45);
    padding: 8px 0px 10px 0px;
    width: 120px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtUpdateDisable {
    background-color: rgba(17, 110, 45, 0.199);
}

.BtCancel {
    margin-left: 5px;
    margin-right: 5px;
    background-color: #C3922E;
    padding: 8px 40px 10px 40px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtCancel:hover {
    background-color: #a37a29;
}

.BtDelete:hover {
    background-color: rgb(122, 35, 46);
    transition: .2s;
}

.BtUpdate:hover {
    background-color: rgb(8, 90, 32);
    transition: .2s;
}

.tableWithSort {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SortIcon {
    position: relative;
    top: 4px;
    margin-right: 10px;
    cursor: pointer;
}

.downloadAndSearch {
    display: flex;
}

@media (min-width:1400px) {
    .ThDel {
        min-width: 80px;
    }
    .ThSite {
        min-width: 200px;
    }
    .ThDate {
        min-width: 270px;
    }
    .ThCar {
        min-width: 300px;
    }
    .ThId {
        min-width: 170px;
    }
}

@media (min-width:1201px) {
    .ThSite {
        width: 250px;
    }
    .ThId {
        width: 250px;
    }
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Title {
        font-size: 40px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .Table {
        direction: rtl;
    }
    .Table {
        /* background-color: red; */
    }
    .Thead {}
    .Tr {
        height: 50px;
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        height: 50px;
        background-color: #F0F5F8;
        color: #101130;
        font-size: 20px;
        text-align: right;
        transition: .2s;
    }
    .TrData td {
        padding-right: 20px;
        padding-left: 50px;
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}

@media (max-width:1200px) and (min-width:768px) {
    .remove {
        width: 100px;
        text-align: center;
        font-size: 15px;
        padding-left: 7px !important;
        padding-right: 7px !important;
        cursor: pointer;
    }
    .edit {
        width: 100px;
        text-align: center;
        font-size: 15px;
        padding-left: 7px !important;
        padding-right: 7px !important;
        cursor: pointer;
    }
    .ThSite {
        width: 250px;
    }
    .ThId {
        width: 250px;
    }
    .InputContainer {
        margin-top: 120px;
        display: flex;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .TableContainer {
        width: 90%;
    }
    .Title {
        font-size: 40px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .Table {
        direction: rtl;
    }
    .Table {
        /* background-color: red; */
    }
    .Thead {}
    .Tr {
        height: 50px;
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        height: 50px;
        background-color: #F0F5F8;
        color: #101130;
        font-size: 20px;
        text-align: right;
        transition: .2s;
        line-height: 20px;
    }
    .TrData td {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}

@media (max-width:767px) {
    .Thead {
        display: none;
    }
    .SortIcon {
        display: none;
    }
    .tableWithSort {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }
    .InputContainer {
        margin-top: 120px;
        display: flex;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .EnterBt {
        background-color: #c3922e;
        color: white;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-top: 20px;
        padding-bottom: 3px;
        border-radius: 50px;
        transition: .2s;
        cursor: pointer;
    }
    .EnterBt:hover {
        transition: .2s;
        background-color: #9b701b;
    }
    .Err {
        margin-top: 5px;
        color: red;
    }
    .FlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .TableContainer {
        width: 90%;
        margin-bottom: 50px;
    }
    .Title {
        font-size: 24px;
        margin-top: 120px;
        margin-bottom: 10px;
    }
    .Table {
        direction: rtl;
    }
    .Tr {
        /* height: 50px; */
        background-color: #D3C086;
        color: #101130;
        font-size: 20px;
    }
    .TrData {
        /* height: 50px; */
        background-color: #F0F5F8;
        color: #101130;
        font-size: 16px;
        text-align: right;
        transition: .2s;
        line-height: 20px;
        margin-bottom: 5px;
    }
    .TrData td {
        direction: rtl;
        text-align: right !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .TrData:hover {
        background-color: #d5dfe6;
        transition: .2s;
    }
}

/* LIST USER START  */

@media (min-width:1201px) {
    .Title {
        font-size: 30px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .InputContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 30px;
        font-size: 20px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 20px;
        width: 250px;
    }
    .List {
        width: 700px;
    }
    .List {
        overflow-y: overlay !important;
    }
    .List::-webkit-scrollbar {
        width: 15px;
        overflow: hidden;
    }
    .List::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .List::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
    .IdContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45%;
        background-color: #a37a29;
        margin-left: 20px;
        position: relative;
        right: 15px;
    }
    .title_id {
        position: relative;
        right: 15px;
        margin-left: 20px;
        font-size: 20px;
        height: 40px;
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .title_site {
        position: relative;
        height: 40px;
        width: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .titleRemove {
        font-size: 20px;
        height: 40px;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
        margin-right: 5px;
    }
    .titleEdit {
        margin-right: 5px;
        font-size: 20px;
        height: 40px;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .title {
        font-size: 20px;
        height: 40px;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .RowTitle {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px;
        height: 50px;
        width: 700px;
        display: flex;
    }
    .Row {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        height: 50px !important;
        width: 700px;
        display: flex;
    }
    /* .Row:hover {
        background-color: #d5dfe6;
    } */
    .SiteContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55%;
        background-color: #F0F5F8;
    }
    .Id {
        position: relative;
        top: -1px;
        color: white;
        font-size: 24px;
    }
    .SiteName {
        position: relative;
        top: -1px;
        color: #101130;
        font-size: 18px;
    }
    .IconContainer1, .IconContainer2 {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F0F5F8;
    }
    .IconContainer2 {
        margin-right: 5px;
    }
    .IconContainer1 {
        margin-right: 5px;
    }
    .RemoveIcon {
        position: relative;
        top: 0px;
        color: #101130;
        transition: .2s;
        cursor: pointer;
    }
    .RemoveIconDisable, .EditIconDisable {
        position: relative;
        top: 0px;
        color: #10113031;
        transition: .2s;
        cursor: pointer;
    }
    .RemoveIcon:hover {
        transition: .2s;
        color: #C3922E;
    }
    .EditIcon {
        position: relative;
        top: 0px;
        color: #101130;
        transition: .2s;
        cursor: pointer;
    }
    .EditIcon:hover {
        transition: .2s;
        color: #C3922E;
    }
    .Select {
        width: 250px;
    }
    .TitleSelect {
        font-size: 20px;
        direction: rtl;
        margin-top: 10px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .panel {
        display: flex;
    }
    .FiltersContainer {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 20px;
    }
    .filterbt {
        font-size: 15px;
        background-color: #101130;
        margin-left: 5px;
        margin-right: 5px;
        color: white;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        transition: .2s;
    }
    .filterbt:hover {
        background-color: #C3922E;
        transition: .2s;
    }
}

@media (max-width:1200px) and (min-width:721px) {
    .List {
        width: 700px;
    }
    .List {
        overflow-y: overlay !important;
    }
    .List::-webkit-scrollbar {
        width: 15px;
        overflow: hidden;
    }
    .List::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .List::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
    .IdContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45%;
        background-color: #a37a29;
        margin-left: 20px;
        position: relative;
        right: 15px;
    }
    .title_id {
        position: relative;
        right: 15px;
        margin-left: 20px;
        font-size: 20px;
        height: 40px;
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .title_site {
        position: relative;
        height: 40px;
        width: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .titleRemove {
        font-size: 20px;
        height: 40px;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
        margin-right: 5px;
    }
    .titleEdit {
        margin-right: 5px;
        font-size: 20px;
        height: 40px;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .Title {
        font-size: 30px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .InputContainer {
        margin-top: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 30px;
        font-size: 20px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 20px;
        width: 250px;
    }
    .List {
        width: 700px;
    }
    .title {
        font-size: 20px;
        height: 40px;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .title_id {
        padding-right: 7px;
        font-size: 20px;
        height: 40px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .RowTitle {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px;
        height: 50px;
        width: 700px;
        display: flex;
    }
    .Row {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        height: 50px !important;
        width: 100%;
        display: flex;
    }
    .SiteContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55%;
        background-color: #F0F5F8;
    }
    .Id {
        position: relative;
        top: -1px;
        color: white;
        font-size: 24px;
    }
    .SiteName {
        position: relative;
        top: -1px;
        color: #101130;
        font-size: 18px;
    }
    .IconContainer1, .IconContainer2 {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F0F5F8;
    }
    .IconContainer2 {
        margin-right: 5px;
    }
    .IconContainer1 {
        margin-right: 5px;
    }
    .RemoveIcon {
        position: relative;
        top: 0px;
        color: #101130;
        transition: .2s;
        cursor: pointer;
    }
    .RemoveIcon:hover {
        transition: .2s;
        color: #C3922E;
    }
    .EditIcon {
        position: relative;
        top: 0px;
        color: #101130;
        transition: .2s;
        cursor: pointer;
    }
    .EditIcon:hover {
        transition: .2s;
        color: #C3922E;
    }
    .Select {
        width: 250px;
    }
    .TitleSelect {
        font-size: 20px;
        direction: rtl;
        margin-top: 10px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .panel {
        display: flex;
    }
    .FiltersContainer {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 20px;
    }
    .filterbt {
        font-size: 15px;
        background-color: #101130;
        margin-left: 5px;
        margin-right: 5px;
        color: white;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        transition: .2s;
    }
    .filterbt:hover {
        background-color: #C3922E;
        transition: .2s;
    }
}

@media (max-width:720px) {
    .List {
        width: 400px;
    }
    .List {
        overflow-y: overlay !important;
    }
    .List::-webkit-scrollbar {
        width: 15px;
        overflow: hidden;
    }
    .List::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .List::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
    .IdContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45%;
        background-color: #a37a29;
        margin-left: 20px;
        position: relative;
        right: 15px;
    }
    .title_id {
        position: relative;
        right: 15px;
        margin-left: 20px;
        font-size: 14px;
        height: 40px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .title_site {
        font-size: 14px;
        position: relative;
        height: 40px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .titleRemove {
        font-size: 14px;
        height: 40px;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
        margin-right: 5px;
    }
    .titleEdit {
        font-size: 14px;
        margin-right: 5px;
        height: 40px;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .Title {
        font-size: 30px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .InputContainer {
        margin-top: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .InputContainer input {
        /* width: 150px; */
        height: 30px;
        font-size: 20px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 20px;
        width: 250px;
    }
    .title {
        font-size: 16px;
        height: 40px;
        width: 60px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .RowTitle {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px;
        height: 50px;
        width: 400px;
        display: flex;
    }
    .RowContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50% !important;
        background-color: rebeccapurple;
    }
    .Row {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px !important;
        width: 100% !important;
    }
    .IdContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        background-color: #a37a29;
        margin-left: 5px;
    }
    .SiteContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        background-color: #F0F5F8;
    }
    .IconContainer1, .IconContainer2 {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F0F5F8;
    }
    .Id {
        position: relative;
        top: -1px;
        color: white;
        font-size: 24px;
    }
    .SiteName {
        position: relative;
        top: -1px;
        color: #101130;
        font-size: 18px;
    }
    .IconContainer2 {
        margin-right: 5px;
    }
    .IconContainer1 {
        margin-right: 5px;
    }
    .RemoveIcon {
        position: relative;
        top: 0px;
        color: #101130;
        transition: .2s;
        cursor: pointer;
    }
    .RemoveIcon:hover {
        transition: .2s;
        color: #C3922E;
    }
    .EditIcon {
        position: relative;
        top: 0px;
        color: #101130;
        transition: .2s;
        cursor: pointer;
    }
    .EditIcon:hover {
        transition: .2s;
        color: #C3922E;
    }
    .Select {
        width: 250px;
    }
    .TitleSelect {
        font-size: 20px;
        direction: rtl;
        margin-top: 10px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .panel {
        display: flex;
    }
    .FiltersContainer {
        min-height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    .FiltersContainer>div {
        display: flex;
        flex-basis: calc(50% - 40px);
        justify-content: center;
        flex-direction: column;
    }
    .filterbt {
        font-size: 14px;
        background-color: #101130;
        margin-left: 5px;
        margin-right: 5px;
        color: white;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        transition: .2s;
        margin-bottom: 5px;
    }
    .filterbt:hover {
        background-color: #C3922E;
        transition: .2s;
    }
}