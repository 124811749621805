.Container {
    /* background-color: violet; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.GlleryTitleMedolContainer {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    min-height: 100% !important;
}

.ItemsContainer {
    margin-top: 20px;
}

.GallryContainer {
    width: 100%;
    position: relative;
    height: auto;
    min-height: 100% !important;
}

.TitleAndModelContainer {
    width: 40%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 10px;
}

.TitleContainer {
    width: 100%;
    height: 100%;
    background-color: #D3C086;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    flex-direction: column;
}

.CarTitle {
    color: #101130;
    font-weight: 600;
    font-size: 30px;
}

.UnderCarTitle {
    color: #101130;
    font-weight: 400;
    font-size: 20px;
    direction: rtl;
}

.CheckIcon {
    top: 3px;
    position: relative;
}

.DontHavaIcon {
    position: relative;
    top: 3px;
}

.Starts {
    position: relative;
    top: 3px;
    color: #C3922E;
}

.MoreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.More {
    direction: rtl;
    width: 90%;
    text-align: right;
    font-size: 20px;
}

.ModelContainer {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.ModelAcordion {
    width: 100%;
}

.ModelAcordion .Collapsible {
    display: none !important;
}

@media (min-width:1301px) {
    .texText {
        font-size: 22px;
        margin-top: -10px;
        margin-bottom: 12px;
        font-weight: 600;
    }
    .supply {
        background-color: #D3C086;
        text-align: center;
        font-size: 22px;
        position: relative;
        bottom: -20px;
        padding-top: 3px;
        padding-bottom: 5px;
        font-weight: 600;
    }
    .GalleryComment {
        font-size: 14px;
        top: 10px;
        right: 10px;
        position: absolute;
        z-index: 1000;
        color: rgb(223, 223, 223);
        text-shadow: 1px 1px 1px #00000080;
    }
    .LinkFlexContainer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }
    .LinkContainer {
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }
    .LinkText {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        /* padding-right: 30px; */
        margin-left: 10px;
    }
    .LinkContainer a {
        font-size: 18px;
        top: -5px;
        position: relative;
    }
    .GlleryTitleMedolContainer {
        width: 1200px;
    }
    .ItemsContainer {
        width: 1200px;
    }
    .TitleAndModelContainer {
        height: 483px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 10px;
        background-color: #10113033;
        height: 1px;
        width: 90%;
    }
    .PriceTitle {
        color: #101130;
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 7px;
    }
    .BigPrice {
        color: #101130;
        font-weight: 400;
        font-size: 40px;
        line-height: 20px;
        font-weight: 600;
        direction: rtl;
    }
    .BigPrice span {
        font-size: 26px;
    }
}

@media (max-width:1300px) and (min-width:1101px) {
    .texText {
        font-size: 18px;
        margin-top: -13px;
        margin-bottom: 2px;
        font-weight: 600;
    }
    .GalleryComment {
        font-size: 14px;
        top: 10px;
        right: 10px;
        position: absolute;
        z-index: 1000;
        color: rgb(223, 223, 223);
        text-shadow: 1px 1px 1px #00000080;
    }
    .LinkFlexContainer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }
    .LinkContainer {
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }
    .LinkText {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        /* padding-right: 30px; */
        margin-left: 10px;
    }
    .LinkContainer a {
        font-size: 18px;
        top: -5px;
        position: relative;
    }
    .CarTitle {
        color: #101130;
        font-weight: 600;
        font-size: 30px;
        margin-top: -7px;
    }
    .TitleAndModelContainer {
        overflow: hidden;
        height: 401px;
    }
    .TitleContainer {
        height: 45% !important;
    }
    .ModelContainer {
        height: 55% !important;
    }
    .supply {
        background-color: #D3C086;
        text-align: center;
        font-size: 22px;
        position: relative;
        bottom: -9px;
        padding-top: 3px;
        padding-bottom: 5px;
        font-weight: 600;
    }
    .Divider {
        margin-top: 13px;
        margin-bottom: 5px;
        background-color: #10113033;
        height: 1px;
        width: 90%;
    }
    .PriceTitle {
        color: #101130;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 5px;
    }
    .BigPrice {
        color: #101130;
        font-weight: 400;
        font-size: 35px;
        line-height: 16px;
        font-weight: 600;
        direction: rtl;
    }
    .BigPrice span {
        font-size: 21px;
    }
    .GlleryTitleMedolContainer {
        width: 1000px;
    }
    .ItemsContainer {
        width: 1000px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
}

@media (min-width:1101px) {
    .MobileView {
        display: none;
    }
}

@media (max-width:1100px) {
    .texText {
        font-size: 22px;
        margin-top: -10px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .supply {
        background-color: #D3C086;
        text-align: center;
        font-size: 22px;
        position: relative;
        bottom: -9px;
        padding-top: 3px;
        padding-bottom: 5px;
        font-weight: 600;
    }
    .DesktopView {
        display: none;
    }
    .GalleryComment {
        font-size: 11px;
        bottom: -14px;
        right: 0px;
        width: 100%;
        position: absolute;
        z-index: 1000;
        color: #101130;
        /* text-shadow: 1px 1px 1px #00000080; */
    }
    .LinkFlexContainer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }
    .LinkContainer {
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }
    .LinkText {
        width: 70%;
        text-align: right;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        /* padding-right: 30px; */
        margin-left: 10px;
    }
    .LinkContainer a {
        width: 30%;
        font-size: 18px;
        top: -5px;
        position: relative;
    }
    .CarTitle {
        color: #101130;
        font-weight: 600;
        font-size: 30px;
        margin-top: 5px;
    }
    .Divider {
        margin-top: 13px;
        margin-bottom: 5px;
        background-color: #10113033;
        height: 1px;
        width: 90%;
    }
    .PriceTitle {
        color: #101130;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 5px;
    }
    .BigPrice {
        color: #101130;
        font-weight: 400;
        font-size: 35px;
        line-height: 16px;
        font-weight: 600;
        direction: rtl;
        margin-bottom: 10px;
    }
    .BigPrice span {
        font-size: 21px;
    }
    .TitleContainer {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    .TitleAndModelContainer {
        width: 100%;
    }
    .GallryContainer {
        width: 100%;
    }
    .GlleryTitleMedolContainer {
        width: 90%;
        flex-direction: column;
    }
    .ItemsContainer {
        width: 90%;
    }
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
    .ModelAcordion {
        margin-top: 20px;
    }
}