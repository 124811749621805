.Container {
    /* background-color: violet; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
}

.GlleryTitleMedolContainer {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    min-height: 100% !important;
}

.ItemsContainer {
    margin-top: 20px;
}

.GallryContainer {
    width: 100%;
    position: relative;
    height: auto;
    min-height: 100% !important;
}

.TitleAndModelContainer {
    width: 40%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 10px;
}

.TitleContainer {
    width: 100%;
    height: 40%;
    background-color: #D3C086;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    flex-direction: column;
}

.CarTitle {
    color: #101130;
    font-weight: 600;
    font-size: 30px;
}

.UnderCarTitle {
    color: #101130;
    font-weight: 400;
    font-size: 20px;
    direction: rtl;
}

.CheckIcon {
    top: 3px;
    position: relative;
}

.DontHavaIcon {
    position: relative;
    top: 3px;
}

.Starts {
    position: relative;
    top: 3px;
    color: #C3922E;
}

.MoreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.More {
    width: 90%;
    text-align: right;
    font-size: 20px;
}

.ModelContainer {
    width: 100%;
    height: 100%;
    background-color: #F6F6F6;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.ModelAcordion {
    width: 100%;
}

.ModelAcordion .Collapsible {
    display: none !important;
}



@media (min-width:1301px) {
    .GlleryTitleMedolContainer {
        width: 1200px;
    }
    .ItemsContainer {
        width: 1200px;
    }
    .TitleAndModelContainer {
        height: 480px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
}

@media (max-width:1300px) and (min-width:1101px) {
    .GlleryTitleMedolContainer {
        width: 1000px;
    }
    .ItemsContainer {
        width: 1000px;
    }
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
}

@media (min-width:1101px) {
    .MobileView {
        display: none;
    }
}

@media (max-width:1100px) {
    .TitleContainer {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    .DesktopView {
        display: none;
    }
    .TitleAndModelContainer {
        width: 100%;
    }
    .GallryContainer {
        width: 100%;
    }
    .GlleryTitleMedolContainer {
        width: 90%;
        flex-direction: column;
    }
    .ItemsContainer {
        width: 90%;
    }
    .TitleAndModelContainer {
        height: 400px;
    }
    .TitleContainer {
        margin-top: 2px;
    }
    .ModelAcordion {
        margin-top: 20px;
    }
}

@media (min-width:1500px) {
    .FilterContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        gap: 40px;
        flex-direction: row-reverse;
    }
    .FilterSet1, .FilterSet2 {
        display: flex;
        gap: 40px;
        flex-direction: row-reverse;
    }
}

@media (max-width:1500px) and (min-width:1000px) {
    .FilterContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        gap: 20px;
        flex-direction: row-reverse;
    }
    .FilterSet1, .FilterSet2 {
        display: flex;
        gap: 20px;
        flex-direction: row-reverse;
    }
}

@media (max-width:999px) {
    .FilterContainer {
        display: none !important;
    }
}