
.MuiFormLabel-root.Mui-focused {
    color: #a37a29 !important;
    font-size: 16px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(30px, -12px) scale(1) !important;
}

.MuiFormLabel-root {
    top: 5px !important;
}

.MuiInputBase-root {
    width: 340px;
    font-size: 24px !important;
    color: #101130 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #a37a29 !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
    text-align: right;
}

#label {
    font-size: 24px;
    font-family: 'Heebo';
    margin-top: 20px;
    margin-bottom: 5px;
    color: #101130;
}

.MuiMenuItem-root {
    justify-content: flex-end !important;
}

.MuiIconButton-label {
    top: 8px !important;
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    position: relative !important;
}

.MuiOutlinedInput-input {
    text-align: center;
    padding: 18.5px 14px;
}

.textField .MuiFormLabel-root {
    /* text-align: right !important;
    width: 100%; */
}

.MuiSelect-iconOutlined {
    left: 7px;
    right: 0px;
}