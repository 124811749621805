.CheckIcon {
    display: none !important;
}

.ItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.AcordionItem {
    direction: rtl;
    justify-content: space-between;
    display: flex;
    height: 100%;
    width: 95%;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: .2s;
}

.AcordionItem:hover {
    background-color: rgb(231, 231, 231);
    transition: .2s;
}

@media (min-width:1201px) {
    .Title {
        font-size: 20px;
        font-weight: 600;
        width: 55%;
        text-align: right;
    }
    .Title {
        font-size: 20px;
        font-weight: 600;
        width: 100%;
        text-align: right;
        direction: rtl;
        padding-right: 18px;
    }
    .Value {
        font-size: 20px;
        font-weight: 400;
        width: 45%;
        text-align: right;
    }
}

@media (max-width:1200px) and (min-width:551px) {
    .Title {
        font-size: 20px;
        font-weight: 600;
        text-align: right;
        padding-right: 18px;
    }
    .Value {
        font-size: 20px;
        font-weight: 400;
        text-align: left;
    }
}

@media (max-width:550px) {
    .Title {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        text-align: right;
        padding-right: 18px;
    }
    .Value {
        width: 60%;
        font-size: 20px;
        font-weight: 400;
        text-align: left;
    }
}

.DividerBottom {
    border-Top: solid #1011303b;
    /* height: 10px; */
    border-width: 1px;
    width: 95%;
}

.DividerTop {
    border-bottom: solid #1011303b;
    /* height: 10px; */
    border-width: 1px;
    width: 95%;
}

.toolTip {
    position: absolute;
    top: 11px;
    padding-left: 4px;
    cursor: pointer;
}

.toolTip path {
    color: #D3C086;
}

.toolTipContent {
    z-index: 2000;
    font-size: 16px !important;
    width: 200px;
    text-align: right;
    direction: rtl !important;
    padding: 10px 10px 10px 10px !important;
    box-shadow: 0px 0px 2px 0px #10113023;
}