.List {
    overflow-y: overlay !important;
}

.List::-webkit-scrollbar {
    width: 15px;
    overflow: hidden;
}

.List::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.List::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.loader {
    top: 320px !important;
    z-index: 10000;
}

.ConfirmDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: fixed;
    width: 450px;
    height: 250px;
    z-index: 1100;
    top: 300px;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.514) 0px 0px 30px;
}

.TitleDelete {
    direction: rtl;
    font-size: 22px;
}

.BtContainer {
    display: flex;
    flex-direction: row-reverse;
}

.BtDelete {
    margin-left: 5px;
    margin-right: 5px;
    background-color: rgb(153, 51, 65);
    padding: 8px 0px 10px 0px;
    width: 120px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtPopDelete {
    margin-left: 5px;
    margin-right: 5px;
    background-color: rgb(153, 51, 65);
    padding: 8px 0px 10px 0px;
    width: 220px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtCancel {
    margin-left: 5px;
    margin-right: 5px;
    background-color: #C3922E;
    padding: 8px 40px 10px 40px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtCancel:hover {
    background-color: #a37a29;
}

.BtDelete:hover {
    background-color: rgb(122, 35, 46);
    transition: .2s;
}

.Title {
    font-size: 30px;
    margin-bottom: 20px;
}

.BtContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DelAll {
    background-color: red;
    width: 150px;
    padding: 10px 10px 10px 10px;
    color: white;
    margin-bottom: 20px;
    font-weight: 400;
    cursor: pointer;
    transition: .2s;
}

.DelAll:hover {
    background-color: #bb0303;
    transition: .2s;
}

.Container {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.InputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.SelectFile {
    width: 150px;
    background-color: #101130;
    color: white;
    padding: 10px 10px 12px 10px;
    font-weight: 400;
    font-size: 22px;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.RowsContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.RowContainer {
    display: flex;
    flex-direction: row-reverse;
    color: white;
    /* width: 600px; */
    align-items: center;
    justify-content: center;
}

.id, .idTitle, .site, .siteTitle {
    margin-left: 10px;
}

.idTitle, .siteTitle {
    background-color: #101130;
    width: 250px;
    height: 40px;
    padding-bottom: 4px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.siteAdminTitle {
    background-color: #101130;
    width: 150px;
    height: 40px;
    padding-bottom: 4px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.err {
    background-color: #f16d6d !important;
}

.siteAdmin {
    background-color: #101130;
    width: 150px;
    height: 40px;
    padding-bottom: 4px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.id, .site {
    color: #101130;
    margin-bottom: 10px;
    background-color: #F0F5F8;
    width: 250px;
    height: 40px;
    padding-bottom: 4px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
}

.SelectFile:hover {
    background-color: #a37a29;
    transition: .2s;
}

@media (min-width:1201px) {}