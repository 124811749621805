@media (min-width:1500px) {
    .FlexContainer {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        width: 100px;
    }
    .Container {
        position: absolute;
        top: -40px;
    }
    .Image img {
        width: 50px;
        margin-bottom: 0px;
    }
    .Image {
        font-size: 0px;
        cursor: pointer;
        height: 50px;
    }
    .Title {
        letter-spacing: -.04em;
        margin-top: 0px;
        font-size: 20px;
        font-weight: 600;
        color: #101130;
    }
}

@media (max-width:1500px) and  (min-width:768px){
    .FlexContainer {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        width: 70px;
    }
    .Container {
        position: absolute;
        top: -40px;
    }
    .Image img {
        width: 40px;
        margin-bottom: 0px;
    }
    .Image {
        font-size: 0px;
        cursor: pointer;
        height: 40px;
    }
    .Title {
        letter-spacing: -.04em;
        margin-top: 0px;
        font-size: 14px;
        font-weight: 600;
        color: #101130;
    }
}