/* .ButtonsContainer {
    background-color: #e4e4e4;
    width: 100%;
    font-size: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.SingleButtonConatiner {
    display: flex;
    align-items: center;
}

.Seperator {
    height: 100px;
    background-color: #7C8A96;
    width: 1px;
}

@media (min-width:1201px) {
    .ButtonItem {
        /* border-style: solid; */
        /* background-color: red; */
        height: 140px;
        cursor: pointer;
    }
    .ButtonItem img {
        background-color: #e4e4e4;
        height: 140px;
    }
}

@media (max-width:1200px) {
    .Seperator {
        height: 50px;
        background-color: #7C8A96;
        width: 1px;
    }
    .ButtonItem {
        height: 70px;
        cursor: pointer;
    }
    .ButtonItem img {
        background-color: #e4e4e4;
        height: 70px;
    }
}

@media (max-width:1400px) and (min-width:1020px) {
    .Seperator {
        height: 50px;
        background-color: #7C8A96;
        width: 1px;
    }
    .ButtonItem {
        height: 100px;
        cursor: pointer;
    }
    .ButtonItem img {
        background-color: #e4e4e4;
        height: 100px;
    }
}

@media (max-width:1020px) and (min-width:768px) {
    .Seperator {
        height: 50px;
        background-color: #7C8A96;
        width: 1px;
    }
    .ButtonItem {
        height: 70px;
        cursor: pointer;
    }
    .ButtonItem img {
        background-color: #e4e4e4;
        height: 70px;
    }
}

@media (max-width:767px) and (min-width:610px) {
    .Seperator {
        height: 50px;
        background-color: #7C8A96;
        width: 1px;
    }
    .ButtonItem {
        height: 120px;
        cursor: pointer;
    }
    .ButtonItem img {
        background-color: #e4e4e4;
        height: 120px;
    }
}


@media (max-width:609px) {
    .Seperator {
        height: 50px;
        background-color: #7C8A96;
        width: 1px;
    }
    .ButtonItem {
        height: 75px;
        cursor: pointer;
    }
    .ButtonItem img {
        background-color: #e4e4e4;
        height: 75px;
    }
}

@media (max-width:410px) {
    .Seperator {
        height: 50px;
        background-color: #7C8A96;
        width: 1px;
    }
    .ButtonItem {
        height: 70px;
        cursor: pointer;
    }
    .ButtonItem img {
        background-color: #e4e4e4;
        height: 70px;
    }
}