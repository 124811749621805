.FlexContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C6C6C6;
    position: fixed;
    z-index: 11000;
    top: 0;
    -webkit-transform: translate3d(0, 0, 0);
}

@media (min-width:768px) {
    .LeftLogo img {
        width: 200px;
    }
    .Container {
        width: 95%;
        height: 100px;
        /* overflow: hidden; */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .SideBySide {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .LeftLogo {
        width: 50%;
        padding-right: 30px;
        top: 3px;
        position: relative;
        text-align: left;
    }
    .RightLogo {
        width: 50%;
        padding-left: 30px;
        top: 4px;
        position: relative;
        text-align: right;
    }
    .RightLogo img {
        width: 250px;
    }
    .CenterDiv {
        width: 100%;
    }
    .CenterDiv svg path:hover {
        color: #C3922E;
    }
    .CenterDiv svg path {
        color: #0F102F;
        cursor: pointer;
    }
    .MobileCenterDiv {
        display: none !important;
    }
}

@media (max-width:767px) and (min-width:451px) {
    .RightLogo img {
        width: 200px;
    }
    .LeftLogo img {
        width: 170px;
    }
    .MobileCenterDiv svg path {
        color: #0F102F;
    }
    .FlexContainer {
        flex-direction: column;
    }
    .Container {
        margin-top: 10px;
        height: 50px;
        /* overflow: hidden; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .SideBySide {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .LeftLogo {
        width: 100%;
        padding-right: 30px;
        top: 3px;
        position: relative;
        text-align: left;
    }
    .LeftLogo svg {
        width: 160px;
    }
    .RightLogo svg {
        width: 90px;
    }
    .RightLogo {
        width: 100%;
        padding-left: 30px;
        top: 4px;
        position: relative;
        text-align: right;
    }
    .CenterDiv {
        display: none;
    }
}

.CenterDivGoBack {
    display: none;
}

@media (max-width:450px) and (min-width:411px) {
    .RightLogo img {
        width: 150px;
    }
    .LeftLogo img {
        width: 140px;
    }
    .MobileCenterDiv svg path {
        color: #0F102F;
    }
    .FlexContainer {
        flex-direction: column;
    }
    .Container {
        margin-top: 10px;
        height: 50px;
        /* overflow: hidden; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .SideBySide {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .LeftLogo {
        width: 100%;
        padding-right: 30px;
        top: 3px;
        position: relative;
        text-align: left;
    }
    .LeftLogo svg {
        width: 160px;
    }
    .RightLogo svg {
        width: 90px;
    }
    .RightLogo {
        width: 100%;
        padding-left: 30px;
        top: 4px;
        position: relative;
        text-align: right;
    }
    .CenterDiv {
        display: none;
    }
}

@media (max-width:410px) {
    .RightLogo img {
        width: 135px;
    }
    .LeftLogo img {
        width: 120px;
    }
    .MobileCenterDiv svg path {
        color: #0F102F;
    }
    .FlexContainer {
        flex-direction: column;
    }
    .Container {
        margin-top: 10px;
        height: 50px;
        /* overflow: hidden; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .SideBySide {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .LeftLogo {
        width: 100%;
        padding-right: 30px;
        top: 3px;
        position: relative;
        text-align: left;
    }
    .LeftLogo svg {
        width: 160px;
    }
    .RightLogo svg {
        width: 90px;
    }
    .RightLogo {
        width: 100%;
        padding-left: 30px;
        top: 4px;
        position: relative;
        text-align: right;
    }
    .CenterDiv {
        display: none;
    }
}

@media (max-width:999px) and (min-width:768px) {
    .CenterDiv {
        display: none;
    }
    .CenterDivGoBack {
        display: block !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}