.ModelName {
    direction: ltr;
}

.FilterCarsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0px;
    background-color: #e4e4e4;
    ;
    direction: rtl;
    flex-direction: row;
    /* background-color: tomato; */
}

.FilterCarsContainer div {
    display: flex;
}

.MoreInfo:hover {
    transition: .2s;
    background-color: #C3922E;
}

.SingleCarContainer:hover {
    background-color: #FFF8EA;
    transition: .5s;
}

.SingleCarContainer img {
    width: 100%;
}

@media (max-width:413px) {}

@media (max-width:767px) {
    .FilterCarsContainer {
        flex-direction: column;
    }
}

.ToggleDashbord {
    margin-top: 50px;
}

.mainContainer {
    background-color: #000510;
}

.DashbordContainer {
    padding-bottom: 41.6%;
    /* by 1920X800 */
    position: relative;
    animation: fadein 1s;
    -moz-animation: fadein 1s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
    /* Opera */
}

.DashbordContainerEmpty {
    padding-bottom: 41.6%;
    /* by 1920X800 */
    position: relative;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {
    /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

object {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.Vimeo {
    /* background-color: #000510; */
}

.VideoButtonContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    z-index: 1;
    /* opacity: .5; */
}

.VimeoContainer {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #000510; */
}

.OpenVideo {
    cursor: pointer;
    position: relative;
    top: 53%;
    left: 41.5%;
    width: 17%;
    height: 20%;
    /* background-color: royalblue; */
}

.CarsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    direction: rtl;
    min-height: 500px;
}

.SingleCarContainer {
    flex: 0 0 30%;
    /* don't grow, don't shrink, width */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #00000036;
    position: relative;
}

@media (min-width:1451px) {
    .CarsContainer {
        width: 1400px;
    }
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .PriceTitle {
        margin-top: 5px;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .MoreInfo {
        font-size: 21px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        background-color: #101130;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 5px;
        padding-top: 3px;
        border-radius: 5px;
        transition: .2s;
    }
    .MobilePic {
        overflow: hidden;
    }
    .MobilePriceAndInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Category {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 5px 10px 5px 10px;
        font-weight: 400;
        background-color: #101130;
        color: white;
        font-size: 20px;
        border-radius: 0px 7px 7px 0px;
    }
    .SingleCarContainer {
        margin: 20px;
        width: 350px;
    }
    .SmallDescription {
        height: 35px;
        width: 100%;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 22px;
        margin-top: 5px;
        color: #101130;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 80%;
        height: 2px;
        background: #C3922E;
    }
    .ModelName {
        font-size: 30px;
        line-height: 30px;
        font-weight: 600;
        color: #C3922E;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .Price {
        color: #C3922E;
        font-family: Heebo;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 5px;
        direction: rtl;
    }
    .Price span {
        font-size: 24px;
    }
}

@media (max-width:1450px) and (min-width:1201px) {
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .PriceTitle {
        margin-top: 5px;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .MoreInfo {
        font-size: 21px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        background-color: #101130;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 5px;
        padding-top: 3px;
        border-radius: 5px;
        transition: .2s;
    }
    .MobilePriceAndInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Price {
        color: #C3922E;
        font-family: Heebo;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 5px;
        direction: rtl;
    }
    .Price span {
        font-size: 24px;
    }
    .ModelName {
        font-size: 27px;
        line-height: 30px;
        font-weight: 600;
        color: #C3922E;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .Category {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 5px 10px 5px 10px;
        font-weight: 400;
        background-color: #101130;
        color: white;
    }
    .Category {
        font-size: 18px;
        border-radius: 0px 7px 7px 0px;
    }
    .CarsContainer {
        width: 1100px;
        /* gap: 30px */
    }
    .SingleCarContainer {
        width: 280px;
        margin: 15px;
    }
    .SmallDescription {
        height: 40px;
        width: 100%;
        direction: rtl;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 24px;
        margin-top: 5px;
        color: #101130;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 80%;
        height: 2px;
        background: #C3922E;
    }
}

@media (max-width:1200px) and (min-width:768px) {
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .PriceTitle {
        margin-top: 5px;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .MoreInfo {
        font-size: 21px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        background-color: #101130;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 5px;
        padding-top: 3px;
        border-radius: 5px;
        transition: .2s;
    }
    .MobilePriceAndInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Price {
        color: #C3922E;
        font-family: Heebo;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 5px;
        direction: rtl;
    }
    .Price span {
        font-size: 24px;
    }
    .ModelName {
        font-size: 27px;
        line-height: 30px;
        font-weight: 600;
        color: #C3922E;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .Category {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 5px 10px 5px 10px;
        font-weight: 400;
        background-color: #101130;
        color: white;
    }
    .Category {
        font-size: 18px;
        border-radius: 0px 7px 7px 0px;
    }
    .CarsContainer {
        width: 750px;
        /* gap: 30px */
    }
    .SingleCarContainer {
        margin: 15px;
        flex: 0 0 45%;
        width: 180px;
    }
    .SmallDescription {
        height: 40px;
        direction: rtl;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 24px;
        margin-top: 5px;
        color: #101130;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 80%;
        height: 2px;
        background: #C3922E;
    }
}

@media (max-width:767px) and (min-width:591px) {
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .PriceTitle {
        margin-top: 5px;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .MoreInfo {
        font-size: 21px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        background-color: #101130;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 5px;
        padding-top: 3px;
        border-radius: 5px;
        transition: .2s;
    }
    .MobilePriceAndInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Price {
        color: #C3922E;
        font-family: Heebo;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 5px;
        direction: rtl;
    }
    .Price span {
        font-size: 24px;
    }
    .ModelName {
        font-size: 22px;
        letter-spacing: -.03em;
        line-height: 25px;
        font-weight: 600;
        color: #C3922E;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    /* .ModelName {
        padding-right: 2px;
        padding-left: 2px;
        font-size: 26px !important;
        line-height: 30px !important;
        margin-bottom: 0px !important;
    } */
    .Category {
        position: absolute;
        left: 0;
        top: 10px;
        padding: 5px 10px 5px 10px;
        font-weight: 400;
        background-color: #101130;
        color: white;
    }
    .Category {
        font-size: 14px;
        border-radius: 0px 7px 7px 0px;
    }
    .CarsContainer {
        width: 560px;
        /* gap: 30px; */
    }
    .SingleCarContainer {
        margin: 15px;
        flex: 0 0 43%;
        width: 140px;
    }
    .SmallDescription {
        height: 40px;
        padding-left: 5px;
        padding-right: 5px;
        direction: rtl;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 24px;
        margin-top: 5px;
        color: #101130;
    }
    .Divider {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 80%;
        height: 2px;
        background: #C3922E;
    }
}

@media (max-width:590px) {
    .CarsFlexContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .CarsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        direction: rtl;
        min-height: 500px;
        flex-direction: column;
        width: 95%;
        /* gap: 25px; */
    }
    .SingleCarContainer {
        margin-bottom: 25px;
        /* flex: 0 0 44%; */
        width: 100%;
    }
    .Category {
        position: absolute;
        right: 0;
        top: 10px;
        padding: 2px 7px 3px 7px;
        font-weight: 400;
        color: white;
        font-size: 14px;
        border-radius: 7px 0px 0px 7px;
        background-color: #101130;
    }
    /* שם רכב ותיאור קצר */
    .MobileContainer {
        display: flex;
    }
    .MobilePic {
        font-size: 0px;
        width: 45%;
    }
    .NameAndDescription {
        padding-right: 10px;
        padding-left: 10px;
        overflow: hidden;
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        direction: rtl;
        overflow: hidden;
    }
    .ModelName {
        font-size: 24px;
        text-align: right;
        line-height: 22px;
        font-weight: 600;
        color: #C3922E;
        /* margin-top: 10px; */
        margin-bottom: 0px;
        margin-bottom: 4px;
    }
    .SmallDescription {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #101130;
        text-align: right;
    }
    .MobilePriceAndInfo {
        display: flex;
        flex-direction: row-reverse;
        /* background-color: #C3922E; */
        background-color: #D3C086;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    /* שם רכב ותיאור קצר */
    .PriceContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-right: 10px;
    }
    .PriceTitle {
        padding-left: 10px;
        font-size: 18px;
        text-align: right;
        line-height: 20px;
        margin-bottom: 0px;
        color: #101130;
    }
    .Price span {
        font-size: 20px;
    }
    .Price {
        position: relative;
        top: 2px;
        padding-left: 20px;
        width: auto;
        color: #101130;
        font-family: Heebo;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: right;
        direction: rtl;
    }
    .MoreInfo {
        width: 100px;
        margin-left: 10px;
        font-size: 20px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 2px;
        padding-top: 0px;
        color: white;
        background-color: #101130;
        border-radius: 5px;
        transition: .2s;
    }
    .Divider {
        display: none;
    }
}

/* @media (max-width:360px) {
    .SingleCarContainer {
        flex: 0 0 45%;
        margin-bottom: 14px !important;
    }
    .CarsContainer {
        gap: 12px;
    }
    .Price {
        font-size: 30px !important;
    }
    .ModelName {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 4px;
        padding-left: 4px;
        font-size: 20px !important;
        line-height: 20px !important;
        margin-bottom: 5px !important;
        height: 30px;
    }
    .SmallDescription {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
        direction: rtl;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 5px;
        line-height: 15px;
        margin-top: 2px;
        color: #101130;
    }
    .MoreInfo {
        margin-top: 5px;
    }
} */