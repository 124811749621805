.FlexContainer {
    width: 100%;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    background-color: #C6C6C6;
    position: fixed;
    z-index: 11000;
    top: 0;
    /* -webkit-transform: translate3d(0, 0, 0); */
}

@media (min-width:1201px) {
    .BiMenu {
        color: #101130;
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
    }
    .divder {
        margin-top: 18px;
        margin-bottom: 15px;
        height: 1px;
        background-color: #ffffff1a;
        width: 100%;
    }
    .AdminHeaderLinkActive {
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        color: #a37a29;
        text-align: right;
    }
    .AdminHeaderLink {
        text-align: right;
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        color: white;
        transition: .2s;
    }
    .AdminHeaderLink:hover {
        color: #a37a29;
        transition: .2s;
    }
    .LeftContainer {
        width: 200px;
        text-align: left;
    }
    .logout {
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        top: -2px;
    }
    .logout:hover {
        color: #a37a29;
    }
    .CenterContainer {
        width: 100%;
        text-align: left;
    }
    .RightContainer img {
        width: 200px;
    }
    .RightContainer {
        width: 200px;
        text-align: right;
    }
}

@media (max-width:1200px) and (min-width:768px) {
    .BiMenu {
        color: #101130;
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
    }
    .divder {
        margin-top: 18px;
        margin-bottom: 15px;
        height: 1px;
        background-color: #ffffff1a;
        width: 100%;
    }
    .AdminHeaderLinkActive {
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        color: #a37a29;
        text-align: right;
    }
    .AdminHeaderLink {
        text-align: right;
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        color: white;
        transition: .2s;
    }
    .AdminHeaderLink:hover {
        color: #a37a29;
        transition: .2s;
    }
    .LeftContainer {
        width: 150px;
        text-align: left;
    }
    .logout {
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        top: -2px;
    }
    .logout:hover {
        color: #a37a29;
    }
    .CenterContainer {
        width: 100%;
        text-align: left;
    }
    .RightContainer img {
        width: 150px;
    }
    .RightContainer {
        width: 150px;
        text-align: right;
    }
}

@media (min-width:766px) {
    .MobileContainer {
        display: none;
    }
}

@media (max-width:767px) {
    .BiMenu {
        color: #101130;
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
    }
    .divder {
        margin-top: 18px;
        margin-bottom: 15px;
        height: 1px;
        background-color: #ffffff1a;
        width: 100%;
    }
    .AdminHeaderLinkActive {
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        color: #a37a29;
        text-align: right;
    }
    .AdminHeaderLink {
        text-align: right;
        outline-style: none !important;
        box-shadow: none;
        border-color: transparent;
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        color: white;
        transition: .2s;
    }
    .AdminHeaderLink:hover {
        color: #a37a29;
        transition: .2s;
    }
    .CenterContainer {
        width: 100%;
        text-align: left;
    }
    .RightContainer img {
        width: 150px;
    }
    .RightContainer {
        width: 150px;
        text-align: right;
    }
    .logout {
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        top: 4px;
    }
    .MobileContainer {
        margin-top: 10px;
    }
}

@media (min-width:768px) {
    .FlexContainer {
        flex-direction: column;
    }
    .LeftLogo img {
        width: 200px;
    }
    .Container {
        width: 95%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .SideBySide {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .LeftLogo {
        width: 50%;
        padding-right: 30px;
        top: 3px;
        position: relative;
        text-align: left;
    }
    .RightLogo {
        width: 50%;
        padding-left: 30px;
        top: 4px;
        position: relative;
        text-align: right;
    }
    .RightLogo img {
        width: 250px;
    }
    .CenterDiv {
        width: 100%;
    }
    .CenterDiv svg path:hover {
        color: #C3922E;
    }
    .CenterDiv svg path {
        color: #0F102F;
        cursor: pointer;
    }
    .MobileCenterDiv {
        display: none !important;
    }
}

@media (max-width:767px) and (min-width:451px) {
    .logos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .rafaelLogo img {
        width: 180px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ShowLogo img {
        width: 200px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .RightLogo img {
        width: 200px;
    }
    .LeftLogo img {
        width: 170px;
    }
    .MobileCenterDiv svg path {
        color: #0F102F;
    }
    .FlexContainer {
        flex-direction: column;
    }
    .Container {
        margin-top: 10px;
        width: 95%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .SideBySide {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .LeftLogo {
        width: 100%;
        padding-right: 30px;
        top: 3px;
        position: relative;
        text-align: left;
    }
    .LeftLogo svg {
        width: 160px;
    }
    .RightLogo svg {
        width: 90px;
    }
    .RightLogo {
        width: 100%;
        padding-left: 30px;
        top: 4px;
        position: relative;
        text-align: right;
    }
}

.CenterDivGoBack {
    display: none;
}

@media (max-width:450px) and (min-width:411px) {
    .logos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .rafaelLogo img {
        width: 140px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ShowLogo img {
        width: 160px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .RightLogo img {
        width: 150px;
    }
    .LeftLogo img {
        width: 140px;
    }
    .MobileCenterDiv svg path {
        color: #0F102F;
    }
    .FlexContainer {
        flex-direction: column;
    }
    .Container {
        margin-top: 5px;
        width: 95%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .SideBySide {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .LeftLogo {
        width: 100%;
        padding-right: 30px;
        top: 3px;
        position: relative;
        text-align: left;
    }
    .LeftLogo svg {
        width: 160px;
    }
    .RightLogo svg {
        width: 90px;
    }
    .RightLogo {
        width: 100%;
        padding-left: 30px;
        top: 4px;
        position: relative;
        text-align: right;
    }
}

@media (max-width:410px) {
    .logos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .rafaelLogo img {
        width: 140px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ShowLogo img {
        width: 160px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .RightLogo img {
        width: 135px;
    }
    .LeftLogo img {
        width: 120px;
    }
    .MobileCenterDiv svg path {
        color: #0F102F;
    }
    .FlexContainer {
        flex-direction: column;
    }
    .Container {
        margin-top: 5px;
        width: 95%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .SideBySide {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .LeftLogo {
        width: 100%;
        padding-right: 30px;
        top: 3px;
        position: relative;
        text-align: left;
    }
    .LeftLogo svg {
        width: 160px;
    }
    .RightLogo svg {
        width: 90px;
    }
    .RightLogo {
        width: 100%;
        padding-left: 30px;
        top: 4px;
        position: relative;
        text-align: right;
    }
}

@media (max-width:999px) and (min-width:768px) {
    .CenterDivGoBack {
        display: block !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}