.container {
    /* background-color: violet; */
    margin-top: 20px;
    /* margin-bottom: 25px; */
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.SpanContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Create a custom radio button */

.checkmark {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    height: 18px;
    width: 18px;
    /* background-color: #eee; */
    border: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
}

.errBorder {
    box-shadow: 0px 0px 10px 3px hsl(0, 100%, 50%);
}

/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
    background-color: #aed7f82c;
}

/* When the radio button is checked, add a blue background */

.container input:checked~.checkmark {
    /* background-color: #2196F3; */
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */

.container .checkmark:after {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #C3922E;
}