.FlexContainer {
    margin-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #808080;
}
.AiOutlineWhatsApp {
    position: relative;
    top: 3px;
    margin-right: 3px;
}

.Container {
    width: 95%;
    height: 90px;
    /* overflow: hidden; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.callus {
    font-size: 18px;
    color: white;
}
.ActiveBranding svg {
    width: 120px;
    cursor: pointer;
}

.RafaelLogo img {
    width: 140px;
}

.CarShowLogo img {
    width: 140px;
}
@media (max-width: 768px) {
    .ActiveBranding {
        display: none;
    }
}

@media (max-width: 550px) {
    .Container {
        width: 90%;
        height: 70px;
    }
    .ActiveBranding svg {
        width: 80px;
        cursor: pointer;
    }
    .RafaelLogo img {
        width: 100px;
    }
    .CarShowLogo img {
        width: 100px;
    }
}
