@media (min-width:768px) {
    .Loader {
        /* position: absolute; */
        z-index: 1000;
        top: 50%;
        width: 40px;
        height: 40px;
        position: absolute;
    }
    .comment {
        position: absolute;
        bottom: 25px;
        font-size: 14px;
        color: red;
    }
    .BackgroundCloseClick {
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: -10;
    }
    .ApproveTitle1 {
        color: #101130;
        font-family: 'Assistant';
        font-size: 40px;
        margin-bottom: 30px;
        font-weight: 600;
        direction: rtl;
        line-height: 40px;
        width: 450px;
    }
    .ApproveTitle2 {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 50px;
        letter-spacing: -.03em;
        margin-bottom: 0px;
        font-weight: 600;
        direction: rtl;
        line-height: 45px;
        margin-top: -10px;
    }
    .idTitle {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 24px;
        margin-bottom: 0px;
        font-weight: 600;
        line-height: 24px;
    }
    .id {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 28px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .logout {
        cursor: pointer;
        position: absolute;
        top: 10px;
        left: 20px;
        color: #101130;
        font-family: 'Assistant';
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 600;
        transition: .2s;
    }
    .logout:hover {
        transition: .2s;
        color: #c3922e;
    }
    .InputContainer input {
        width: 150px;
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .InputContainer input:focus {
        outline: none;
    }
    .SendBtActive {
        cursor: pointer;
        background-color: #c3922e;
        padding: 5px 5px 7px 5px;
        width: 150px;
        color: white;
        font-weight: 600;
        font-size: 30px;
        border-radius: 5px;
        margin-top: 20px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: -moz-none;
        -o-user-select: none;
        user-select: none;
        transition: .2s;
    }
    .SendBtActive:hover {
        transition: .2s;
        background-color: #9b7325;
    }
    .SendBt {
        background-color: #f8e7c4;
        padding: 5px 5px 7px 5px;
        width: 150px;
        color: white;
        font-weight: 600;
        font-size: 30px;
        border-radius: 5px;
        margin-top: 20px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: -moz-none;
        -o-user-select: none;
        user-select: none;
    }
    .Mobile {
        display: none !important;
    }
    .FlexContainer {
        justify-content: center;
        align-items: center;
        background-color: #101130b6;
        position: absolute;
        z-index: 5000;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        /* transition: opacity .2s ease-in-out; */
    }
    .form {
        transition: .2s ease-in-out;
        position: relative;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 740px;
        height: 600px;
        flex-direction: column;
    }
    .CloseFrom {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: #c3922e;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: white;
        font-size: 20px;
        border-radius: 5px;
    }
}

@media (max-width:767px) and (min-width:411px) {
    .idTitle {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 24px;
        margin-bottom: 0px;
        font-weight: 600;
        line-height: 24px;
    }
    .id {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 28px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .logout {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 20px;
        color: #101130;
        font-family: 'Assistant';
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 600;
        transition: .2s;
    }

    .Loader {
        position: absolute;
        z-index: 1000;
        top: 7%;
        width: 40px;
        height: 40px;
    }
    .comment {
        position: relative;
        bottom: 0px;
        font-size: 14px;
        color: red;
    }
    .BackgroundCloseClick {
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: -10;
    }
    .FlexContainer {
        transition: left .5s ease-in-out;
        justify-content: flex-end;
        align-items: center;
        background-color: #101130b6;
        position: absolute;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
    }
    .form {
        position: relative;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        right: 0;
        height: 100%;
        flex-direction: column;
    }
    .InputContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .ApproveTitle1 {
        color: #101130;
        font-family: 'Assistant';
        font-size: 30px;
        margin-bottom: 30px;
        font-weight: 600;
        direction: rtl;
        line-height: 30px;
        width: 90%;
    }
    .ApproveTitle2 {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 40px;
        letter-spacing: -.03em;
        margin-bottom: 0px;
        font-weight: 600;
        direction: rtl;
        line-height: 35px;
        margin-top: -10px;
    }

    .InputContainer input {
        width: 150px;
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .InputContainer input:focus {
        outline: none;
    }
    .SendBtActive {
        cursor: pointer;
        background-color: #c3922e;
        padding: 5px 5px 7px 5px;
        width: 150px;
        color: white;
        font-weight: 600;
        font-size: 30px;
        border-radius: 5px;
        margin-top: 20px;
    }
    .SendBt {
        cursor: pointer;
        background-color: #f8e7c4;
        padding: 5px 5px 7px 5px;
        width: 150px;
        color: white;
        font-weight: 600;
        font-size: 30px;
        border-radius: 5px;
        margin-top: 20px;
    }
    .Desktop {
        display: none !important;
    }
    .CloseFrom {
        position: absolute;
        left: 10px;
        top: 10px;
        background-color: #c3922e;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: white;
        font-size: 20px;
        border-radius: 5px;
    }
}

@media (max-width:410px) {
    .idTitle {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 24px;
        margin-bottom: 0px;
        font-weight: 600;
        line-height: 24px;
    }
    .id {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 28px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .logout {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 20px;
        color: #101130;
        font-family: 'Assistant';
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 600;
        transition: .2s;
    }
    
    .comment {
        position: relative;
        /* bottom: 135px; */
        font-size: 14px;
        color: red;
    }
    .Loader {
        position: absolute;
        z-index: 1000;
        top: 20px;
        width: 40px;
        height: 40px;
    }
    .BackgroundCloseClick {
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: -10;
    }
    .FlexContainer {
        transition: left .5s ease-in-out;
        justify-content: flex-end;
        align-items: center;
        background-color: #101130b6;
        position: absolute;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
    }
    .form {
        position: relative;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        right: 0;
        height: 100%;
        flex-direction: column;
    }
    .InputContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .ApproveTitle1 {
        color: #101130;
        font-family: 'Assistant';
        font-size: 30px;
        margin-bottom: 30px;
        font-weight: 600;
        direction: rtl;
        line-height: 30px;
        width: 90%;
    }
    .ApproveTitle2 {
        color: #c3922e;
        font-family: 'Assistant';
        font-size: 40px;
        letter-spacing: -.03em;
        margin-bottom: 0px;
        font-weight: 600;
        direction: rtl;
        line-height: 35px;
        margin-top: -10px;
    }

    .InputContainer input {
        width: 150px;
        height: 40px;
        font-size: 24px;
        padding-top: 2px;
        font-weight: 500;
        font-family: 'Heebo';
        color: #101130;
        text-align: center;
        margin-bottom: 10px;
    }
    .InputContainer input:focus {
        outline: none;
    }
    .SendBtActive {
        background-color: #c3922e;
        padding: 5px 5px 7px 5px;
        width: 150px;
        color: white;
        font-weight: 600;
        font-size: 30px;
        border-radius: 5px;
        margin-top: 20px;
    }
    .SendBt {
        background-color: #f8e7c4;
        padding: 5px 5px 7px 5px;
        width: 150px;
        color: white;
        font-weight: 600;
        font-size: 30px;
        border-radius: 5px;
        margin-top: 20px;
    }
    .Desktop {
        display: none !important;
    }
    .CloseFrom {
        position: absolute;
        left: 10px;
        top: 10px;
        background-color: #c3922e;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: white;
        font-size: 20px;
        border-radius: 5px;
    }
}