.RightLogo {
    cursor: pointer;
}

.ShowAllCarsContainer {
    background-color: #C6C6C6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ShowAllCars {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    padding-bottom: 2px;
    color: white;
    background-color: #101130;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ShowAllCars:hover {
    background-color: #C3922E;
    transition: .2s;
}

@media (min-width:1501px) {
    .RightLogoManager {
        cursor: pointer;
        /* background-color: yellowgreen; */
    }
    .RightLogoManager img {
        width: 200px;
    }
    .SideBySideManagers {
        /* background-color: yellowgreen; */
    }
    .LeftLogo img {
        width: 210px;
    }
    .RightLogo img {
        width: 200px;
    }
    .RightLogo2 img {
        width: 250px;
    }
    .MobileContainer {
        display: none !important;
    }
    .SideBySide {
        margin-right: 70px;
        top: 5px;
        position: relative;
    }
    .SideBySide img {
        position: relative;
        top: -1px;
        height: 60px;
    }
    .ClearFilter {
        margin-right: 70px;
        width: 70px;
    }
    .PriceSlider {
        position: relative;
        top: -3px;
        margin-right: 70px;
    }
    .SelectManufacturer {
        width: 200px;
        direction: rtl;
    }
}

@media (max-width:1500px) and (min-width:1301px) {
    .LeftLogo img {
        width: 180px;
        position: relative;
        top: -2px;
    }
    .RightLogoManager {
        cursor: pointer;
        /* background-color: yellowgreen; */
    }
    .RightLogoManager img {
        width: 200px;
    }
    .SideBySideManagers {
        /* background-color: yellowgreen; */
    }
    .LeftLogoMangers {
        /* background-color: yellowgreen; */
    }
    .RightLogo img {
        width: 160px;
    }
    .RightLogo2 img {
        width: 200px;
    }
    .MobileContainer {
        display: none !important;
    }
    .SideBySide {
        margin-right: 45px;
        position: relative;
        top: 1px;
    }
    .SideBySide img {
        position: relative;
        top: 3px;
        height: 55px;
    }
    .ClearFilter {
        width: 100px;
        margin-right: 30px;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .PriceSlider {
        position: relative;
        top: -3px;
        margin-right: 30px;
    }
    .SelectManufacturer {
        width: 200px;
        direction: rtl;
    }
}

@media (max-width:1300px) and (min-width:1201px) {
    .LeftLogo img {
        width: 150px;
    }
    .RightLogoManager {
        cursor: pointer;
        /* background-color: yellowgreen; */
    }
    .RightLogoManager img {
        width: 200px;
    }
    .SideBySideManagers {
        /* background-color: yellowgreen; */
    }
    .LeftLogoMangers {
        /* background-color: yellowgreen; */
    }
    .RightLogo img {
        width: 150px;
    }
    .RightLogo2 img {
        width: 180px;
    }
    .MobileContainer {
        display: none !important;
    }
    .SideBySide {
        position: relative;
        top: 3px;
        margin-right: 40px;
    }
    .SideBySide img {
        height: 55px;
    }
    .ClearFilter {
        padding-left: 10px !important;
        padding-right: 10px !important;
        width: 70px;
        margin-right: 20px;
    }
    .PriceSlider {
        position: relative;
        top: -3px;
        margin-right: 20px;
    }
    .SelectManufacturer {
        width: 200px;
        direction: rtl;
    }
}

@media (max-width:1200px) and (min-width:412px) {
    .MobileContainerManagers {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .MobileHeaderManagers {
        width: 50%;
        display: flex;
    }
    .RightLogoManager {
        cursor: pointer;
        width: 100%;
        background-color: yellowgreen;
    }
    .RightLogoManager img {
        width: 200px;
    }
    .SideBySideManagers {
        width: 100%;
        background-color: yellowgreen;
    }
    .MobileSideBySideMangers {
        width: 100%;
    }
    .LeftLogoMangers {
        width: 100%;
        /* background-color: yellowgreen; */
    }
    .MobileSideBySideContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .MobileSideBySide {
        display: flex;
        width: 400px;
        align-items: center;
        justify-content: center;
    }
    .SideBySideIcon {
        width: 100%;
    }
    .RightLogo {
        width: 100%;
    }
    .RightLogo img {
        width: 160px;
    }
    .MobileHeader {
        width: 90%;
    }
    .PriceAndClear {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .SideBySide svg {
        margin-top: 25px;
        margin-bottom: 15px;
    }
    .SideBySide img {
        position: relative;
        top: 3px;
        height: 55px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .ClearFilter {
        position: relative;
        top: 3px;
    }
    .PriceSlider {
        width: 60%;
        padding-left: 0px;
    }
    .SliderPrice {
        width: 90% !important;
    }
    .Container {
        display: none !important;
    }
    .MobileContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .MobileFlexContainer {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .LeftLogo2 {
        margin-right: 20px;
        padding-bottom: 10px;
    }
    .RightLogo2 svg {
        height: 40px;
        padding-bottom: 10px;
    }
    .SideBySide {
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-top: 30px;
    }
    .Container {
        width: 100% !important;
    }
    .SelectManufacturer {
        width: 100%;
        direction: rtl;
        padding-right: 15px;
        margin-top: 30px;
    }
    .FilteItems {
        width: 100%;
    }
}

@media (max-width:411px) {
    .MobileSideBySideContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .MobileSideBySide {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .SideBySideIcon {}
    .RightLogo {
        width: 100%;
    }
    .RightLogo img {
        width: 130px;
    }
    .SideBySide svg {
        margin-top: 25px;
        margin-bottom: 15px;
        width: 150px;
    }
    .ClearFilter {
        position: relative;
        top: 3px;
    }
    .MobileHeader {
        width: 90%;
    }
    .PriceAndClear {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .PriceSlider {
        width: 60%;
        padding-left: 0px;
    }
    .SliderPrice {
        width: 90% !important;
    }
    .Container {
        display: none !important;
    }
    .MobileContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C6C6C6;
    }
    .MobileFlexContainer {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .LeftLogo2 {
        margin-right: 20px;
        padding-bottom: 10px;
    }
    .RightLogo2 svg {
        height: 40px;
        padding-bottom: 10px;
    }
    .SideBySide {
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-top: 30px;
    }
    .SideBySide img {
        position: relative;
        top: 3px;
        height: 55px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .Container {
        width: 100% !important;
    }
    .SelectManufacturer {
        width: 100%;
        direction: rtl;
        padding-right: 15px;
        margin-top: 30px;
    }
    .FilteItems {
        width: 100%;
    }
}

.LeftLogo {
    padding-right: 30px;
    top: 3px;
    position: relative;
}

.RightLogo {
    padding-left: 30px;
    top: 4px;
    position: relative;
}

.FilteItems {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ClearFilter {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    padding-bottom: 2px;
    color: white;
    background-color: #101130;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.ClearFilter:hover {
    background-color: #C3922E;
    transition: .2s;
}

.PriceRange {
    font-size: 22px;
    font-weight: 600;
}

.FlexContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C6C6C6;
}

.Container {
    width: 95%;
    height: 100px;
    /* overflow: hidden; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SelectTeam {
    text-align: right;
    --styled-select-placeholder__color: #0F102F;
    --styled-select-placeholder__font-family: 'Assistant', sans-serif;
    --styled-select-placeholder__font-size: 22px;
    --styled-select-placeholder__font-weight: 600;
    --styled-select-placeholder__padding: 0px 10px 0px 0px;
    --styled-select__color: #0F102F;
    --styled-select__font-size: 22px;
    --styled-select-arrow-zone__width: 60px;
    --styled-select-arrow-zone__width: 50px;
    --styled-select-arrow__color: white;
    --styled-select-control__border-color: #C6C6C6;
    --styled-select-value-label__font-family: 'Assistant', sans-serif;
}