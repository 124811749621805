
.ConfirmAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: fixed;
    width: 450px;
    height: 250px;
    z-index: 1100;
    top: 300px;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.514) 0px 0px 30px;
}
.TitleAdd {
    direction: rtl;
    font-size: 22px;
}
.BtCancel {
    margin-left: 5px;
    margin-right: 5px;
    background-color: #C3922E;
    padding: 8px 40px 10px 40px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtCancel:hover {
    background-color: #a37a29;
}


.BtContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    top: 320px !important;
    z-index: 10000;
}

.errText {
    font-size: 20px;
    color: red;
    margin-bottom: 5px;
}

.errClick {
    font-size: 20px;
    background-color: #d2d2d2;
    padding: 5px 15px 7px 15px;
    margin: 5px 5px 15px 5px;
    cursor: pointer;
    border-radius: 5px;
}

.errClick:hover {
    color: #C3922E;
}

.FiltersContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.List {
    overflow-y: overlay !important;
}

.List::-webkit-scrollbar {
    width: 15px;
    overflow: hidden;
}

.List::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.List::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.BtAdd {
    margin-left: 5px;
    margin-right: 5px;
    background-color: #C3922E;
    padding: 8px 40px 10px 40px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.BtAddDisable {
    margin-left: 5px;
    margin-right: 5px;
    background-color: #c3912e4d;
    padding: 8px 40px 10px 40px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.Title {
    font-size: 30px;
    margin-bottom: 10px;
}

.BtAdd:hover {
    background-color: #a37a29;
}

.DelAll {
    background-color: red;
    width: 150px;
    padding: 10px 10px 10px 10px;
    color: white;
    margin-bottom: 20px;
    font-weight: 400;
    cursor: pointer;
    transition: .2s;
}

.DelAll:hover {
    background-color: #bb0303;
    transition: .2s;
}

.Container {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.InputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.SelectFile {
    width: 150px;
    background-color: #101130;
    color: white;
    padding: 10px 10px 12px 10px;
    font-weight: 400;
    font-size: 22px;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
}

.RowsContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.RowContainer {
    display: flex;
    flex-direction: row-reverse;
    color: white;
    width: 600px;
    align-items: center;
    justify-content: center;
}

.id, .idTitle, .site, .siteTitle {
    margin-left: 10px;
}

.idTitle, .siteTitle {
    font-size: 18px;
    background-color: #101130;
    width: 250px;
    height: 40px;
    padding-bottom: 10px;
    padding-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rowNum {
    color: red;
    position: absolute;
    right: 20px;
    top: 14px;
}

.example {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 18px;
    direction: rtl;
}

.downloadicon {
    position: relative;
    top: 4px;
    padding-right: 5px;
}

.info {
    direction: rtl;
    font-size: 15px;
}

.siteAdminTitle {
    background-color: #101130;
    width: 150px;
    height: 40px;
    padding-bottom: 4px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.err {
    background-color: #f16d6d !important;
}

.siteAdmin {
    background-color: #101130;
    width: 150px;
    height: 40px;
    padding-bottom: 4px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.id, .site {
    color: #101130;
    margin-bottom: 10px;
    background-color: #F0F5F8;
    width: 250px;
    height: 40px;
    padding-bottom: 4px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
}

.SelectFile:hover {
    background-color: #a37a29;
    transition: .2s;
}

@media (min-width:1201px) {}