/* .ToggleDashbord {
    margin-top: 50px;
} */

/* .mainContainer{
   background-color: #000510;
} */

.DashbordContainer {
    padding-bottom: 41.6%;
    /* by 1920X800 */
    position: relative;
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
    /* Opera */
}

.MobileDashbordContainer {
    padding-bottom: 80%;
    /* by 1920X800 */
    position: relative;
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
    /* Opera */
}

.DashbordContainerEmpty {
    padding-bottom: 41.6%;
    /* by 1920X800 */
    position: relative;
    background-color: #101130;
}

.MobileashbordContainerEmpty {
    padding-bottom: 80%;
    /* by 1920X800 */
    position: relative;
    background-color: #101130;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {
    /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.MobileVimeoContainer {
    overflow: hidden;
    margin-bottom: 0px;
    /* width: 100vw; */
}

object {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.Vimeo {
    /* background-color: #000510; */
}

.VideoButtonContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    z-index: 1;
    /* opacity: .5; */
}

.MobileVideoButtonContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    z-index: 1;
    opacity: .5;
}

.VimeoContainer {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #000510; */
}

.OpenVideo {
    cursor: pointer;
    position: relative;
    top: 61%;
    left: 41.5%;
    width: 17%;
    height: 20%;
    /* background-color: royalblue; */
}

.MobileOpenVideo {
    cursor: pointer;
    position: relative;
    top: 55%;
    left: 58%;
    width: 38%;
    height: 25%;
    /* background-color: royalblue; */
}